import React from 'react'
import RDatePicker from 'react-datepicker'
import TextField from './TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CalendarIcon from '@mui/icons-material/Event'
import { FieldType } from '../Types/Field'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'

const DatePicker = ({ label, value, onChange, error, helperText, required }) => {
  const handleChange = date => {
    console.log('HANDLE CHANGHE', date)
    onChange(moment(date).toISOString())
  }

  return (
    <RDatePicker
      selected={value ? moment(value).toDate() : null}
      onChange={handleChange}
      customInput={
        <TextField
          error={error}
          required={required}
          label={label + (required ? ' *' : '')}
          style={{ width: '100%' }}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarIcon style={{ color: '#999' }}/>
              </InputAdornment>
            ),
          }}
        />
      }
    />
  )
}

DatePicker.propTypes = {
  ...FieldType,
}

export default DatePicker
