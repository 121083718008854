import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const deviceStatusesSlice = createSlice(apiSlice('deviceStatuses', []))

export const { request, success, failure } = deviceStatusesSlice.actions

export default deviceStatusesSlice.reducer

export const selectDeviceStatuses = (state) => state.deviceStatuses
export const selectDeviceStatusesData = (state) => state.deviceStatuses.data

// side effects
export const fetchDeviceStatusesEpic = (action$) =>
  action$.pipe(
    ofType('deviceStatuses/request'),
    mergeMap(() =>
      from(api.deviceStatuses.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'devices:errors.FetchDeviceStatusesError',
        ),
      ),
    ),
  )
