import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 24 24" style={style}><path fill={color} d="M17.5,15.2L22,7.3V19v2H2V3h2v12.5L9.5,6L16,9.8l4.2-7.3l1.7,1l-5.2,9.1l-6.5-3.8L4.3,19h2.3l4.4-7.6L17.5,15.2	z"/></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
