import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const vehicleStatusesSlice = createSlice(apiSlice('vehicleStatuses', []))

export const { request, success, failure } = vehicleStatusesSlice.actions

export default vehicleStatusesSlice.reducer

export const selectVehicleStatuses = (state) => state.vehicleStatuses
export const selectVehicleStatusesData = (state) => state.vehicleStatuses.data

// side effects
export const fetchVehicleStatusesEpic = (action$) =>
  action$.pipe(
    ofType('vehicleStatuses/request'),
    mergeMap(() =>
      from(api.vehicleStatuses.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'vehicles:errors.FetchVehicleStatusesError',
        ),
      ),
    ),
  )
