import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const rjApiSlice = apiSlice('resourcesJobs', [])
rjApiSlice.reducers.patchProgress = (state, action) => {
  const { resourceGuid, progress, hasError } = action.payload
  return {
    ...state,
    data: state.data.map((j) =>
      j.ecuDataFile.guid === resourceGuid ? { ...j, importProgress: progress, hasError: hasError } : j,
    ),
  }
}

const resourcesJobsSlice = createSlice(rjApiSlice)

export const { request, success, failure, patchProgress } = resourcesJobsSlice.actions

export default resourcesJobsSlice.reducer

export const selectResourcesJobs = (state) => state.resourcesJobs
export const selectResourcesJobsData = (state) => state.resourcesJobs.data

// side effects
export const fetchResourcesJobsEpic = (action$) =>
  action$.pipe(
    ofType('resourcesJobs/request'),
    mergeMap(() =>
      from(api.resourcesJobs.get()).pipe(
        ...manageEpicResponse(success, failure, 'resources:errors.FetchResourcesJobsError'),
      ),
    ),
  )
