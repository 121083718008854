import { createSlice } from '@reduxjs/toolkit'
import api from '../Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const gpdPhasesSlice = createSlice(apiSlice('gpdPhases', []))

export const { request, success, failure } = gpdPhasesSlice.actions

export default gpdPhasesSlice.reducer

export const selectGpdPhases = (state) => state.gpdPhases
export const selectGpdPhasesData = (state) => state.gpdPhases.data

// side effects
export const fetchGpdPhasesEpic = (action$) =>
  action$.pipe(
    ofType('gpdPhases/request'),
    mergeMap(() =>
      from(api.gpdPhases.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchGpdPhasesError',
        ),
      ),
    ),
  )
