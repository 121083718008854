import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import VehiclesList from './VehiclesList'
import VehiclesFleetsList from './VehiclesFleetsList'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 350px;
`

const VehiclesSidebar = () => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  return (
    <Wrapper>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t('common:generic.Vehicles')} />
        <Tab label={t('common:generic.Fleets')} />
      </Tabs>
      {value === 0 && (
        <VehiclesList />
      )}
      {value === 1 && (
        <VehiclesFleetsList />
      )}
    </Wrapper>
  )
}

VehiclesSidebar.propTypes = {}

export default VehiclesSidebar
