export const columns = (t) => [
  {
    id: 'id',
    label: t('resources:fields.id'),
    numeric: true,
    width: '40px',
  },
  {
    id: 'guid',
    label: t('resources:fields.guid'),
  },
  {
    id: 'fileName',
    label: t('resources:fields.fileName'),
  },
  {
    id: 'ecuDataFileType.code',
    label: t('resources:fields.ecuDataFileType.code'),
  },
  {
    id: 'ecuDataFileType.extensions',
    label: t('resources:fields.ecuDataFileType.extensions'),
  },
  {
    id: 'fileSize',
    label: t('resources:fields.fileSize'),
    numeric: true,
  },
  {
    id: 'importCompleted',
    label: t('resources:fields.importCompleted'),
    boolean: true,
  },
  {
    id: 'url',
    label: t('resources:fields.url'),
  },
  {
    id: 'tag',
    label: t('resources:fields.tag'),
  },
  {
    id: 'version',
    label: t('resources:fields.version'),
  },
  {
    id: 'archived',
    label: t('resources:fields.archived'),
    boolean: true,
  },
  {
    id: 'user.email',
    label: t('resources:fields.user.email'),
  },
  {
    id: 'user.name',
    label: t('resources:fields.user.name'),
  },
  {
    id: 'user.surname',
    label: t('resources:fields.user.surname'),
  },
  {
    id: 'createdBy',
    label: t('resources:fields.createdBy'),
  },
  {
    id: 'createdOn',
    label: t('resources:fields.createdOn'),
    datetime: true,
  },
  {
    id: 'updatedBy',
    label: t('resources:fields.updatedBy'),
  },
  {
    id: 'updatedOn',
    label: t('resources:fields.updatedOn'),
    datetime: true,
  },
]
