import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const deviceTypesSlice = createSlice(apiSlice('deviceTypes', []))

export const { request, success, failure } = deviceTypesSlice.actions

export default deviceTypesSlice.reducer

export const selectDeviceTypes = (state) => state.deviceTypes
export const selectDeviceTypesData = (state) => state.deviceTypes.data

// side effects
export const fetchDeviceTypesEpic = (action$) =>
  action$.pipe(
    ofType('deviceTypes/request'),
    mergeMap(() =>
      from(api.deviceTypes.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'devices:errors.FetchDeviceTypesError',
        ),
      ),
    ),
  )
