import PropTypes from 'prop-types'
import { DeviceType } from './Device'
import { UserType } from '../../Auth/Types/User'

export const DevicesFleetType = PropTypes.shape({
  id: PropTypes.number,
  createdBy: PropTypes.string,
  createdOn: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  segmentId: PropTypes.number,
  segment: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  notes: PropTypes.string,
  ownerId: PropTypes.number,
  owner: PropTypes.shape(UserType),
  devices: PropTypes.arrayOf(DeviceType),
})
