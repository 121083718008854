import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectResourcesData, selectResources, request as resourcesRequest } from '../Redux/Resources'
import {
  selectResourcesJobsData,
  selectResourcesJobs,
  patchProgress,
  request as resourcesJobsRequest,
} from '../Redux/ResourcesJobs'
import { useTranslation } from 'react-i18next'
import Signalr from '../../Common/Components/Signalr'
import { FlexColumn } from '../../Common/Components/Flex'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ResourcesSidebar from '../Components/ResourcesSidebar'
import ResourcesList from '../Components/ResourcesList'
import ResourcesJobsList from '../Components/ResourcesJobsList'
import BaseLayout from '../../Core/Layouts/BaseLayout'
// import { propOrDefault } from '../../Common/Utils/Objects'
import config from '../../Core/Config'
import moment from 'moment'

const AdminResourcesView = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const resources = useSelector(selectResourcesData)
  const fetched = useSelector(selectResources).fetched
  const resourcesJobs = useSelector(selectResourcesJobsData)
  const jobsFetched = useSelector(selectResourcesJobs).fetched
  const breadcrumbs = [{ label: t('core:navigation.Resources'), handleClick: () => history.push(config.urls.users) }]

  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue)
  }

  const handleSocketMessage = (message) => {
    console.log(message)
    dispatch(
      patchProgress({ resourceGuid: message.ecuDataFileId, progress: message.progress, hasError: message.hasError }),
    )
    // update data
    if (message.progress === 100) {
      dispatch(resourcesRequest())
      dispatch(resourcesJobsRequest())
    }
  }

  // sidebar form
  const [fields, setFields] = React.useState({
    fileName: null,
    updatedOn: null,
    updatedBy: null,
    tag: null,
    fileSize: 0,
    archived: false,
    resourceTypeId: null,
    // segmentId: null,
  })

  const handleFieldChange = (field, value) => {
    setFields({ ...fields, [field]: value })
  }

  const filteredResources = resources.filter((r) => {
    const { fileName, segmentId, resourceTypeId, updatedOn, updatedBy, tag, fileSize, archived } = fields
    if (fileName && !r.fileName.toLowerCase().includes(fileName.toLowerCase())) return false
    if (segmentId && r.segmentId !== segmentId) return false
    if (resourceTypeId && r.ecuDataFileTypeId !== resourceTypeId) return false
    if (updatedBy && !r.updatedBy.toLowerCase().includes(updatedBy.toLowerCase())) return false
    if (updatedOn && moment(updatedOn).format('YYYY-MM-DD') !== moment(r.updatedOn).format('YYYY-MM-DD')) return false
    if (tag && !r.tag.includes(tag)) return false
    if (r.fileSize < fileSize) return false
    if (!archived && r.archived) return false

    return true
  })

  const filteredResourcesJobs = resourcesJobs.filter((r) => {
    const { fileName, resourceTypeId } = fields
    if (fileName && !r.ecuDataFile.fileName.toLowerCase().includes(fileName.toLowerCase())) return false
    if (resourceTypeId && r.ecuDataFile.ecuDataFileTypeId !== resourceTypeId) return false
    return true
  })

  return (
    <BaseLayout
      showMenu
      breadcrumbs={breadcrumbs}
      sidebar={
        <ResourcesSidebar
          fields={fields}
          onFieldChange={handleFieldChange}
          totalFiltered={tabValue === 0 ? filteredResources.length : filteredResourcesJobs.length}
          total={tabValue === 0 ? resources.length : resourcesJobs.length}
          loading={!fetched}
          tab={tabValue}
        />
      }
    >
      <Signalr hubName="upload" listener={handleSocketMessage} />
      <FlexColumn style={{ padding: '1rem' }}>
        <Tabs value={tabValue} onChange={handleTabChange} style={{ marginBottom: '1rem' }}>
          <Tab label={t('resources:ui.AvailableDatafiles')} />
          <Tab label={t('resources:ui.Jobs')} />
        </Tabs>
        {tabValue === 0 && <ResourcesList resources={filteredResources} loading={!fetched} />}
        {tabValue === 1 && <ResourcesJobsList resourcesJobs={filteredResourcesJobs} loading={!jobsFetched} />}
      </FlexColumn>
    </BaseLayout>
  )
}

export default AdminResourcesView
