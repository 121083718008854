import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import storageService from '../../../Common/Services/Storage'
import Typography from '@mui/material/Typography'
import ActionsButton from '../ActionsButton'
import Dropdown from '../Dropdown'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'
import SettingsIcon from '@mui/icons-material/Settings'
import TrueIcon from '@mui/icons-material/Done'
import FalseIcon from '@mui/icons-material/Clear'
import SettingsDialog from './SettingsDialog'
import { setStateFromEvent } from '../../Utils/Events'
import Link from '@mui/material/Link'
import { FlexRow } from '../../../Common/Components/Flex'
import styled from 'styled-components'
import moment from 'moment'
import * as R from 'ramda'

const Toolbar = styled.div`
  align-items: center;
  display: flex;
  background: #e3e3e3;
  justify-content: space-between;
  padding: 0.5rem 1.3rem;

  div {
    font-weight: bold;
    font-size: 0.9rem !important;
  }
`
const BulkActions = styled.div`
  align-items: flex-end;
  display: flex;
  background: #fff;
  padding: 1rem 0;
`

const SelectAll = styled(Typography)`
  border-left: 1px solid #eee;
  margin-left: 1rem !important;
  padding-left: 1rem;
`

const SelectAllLink = styled(Link)`
  cursor: pointer;
`

const ChangeListTable = ({
  name,
  autoWidth,
  data,
  idField,
  selectable,
  selected,
  onSelect,
  columns,
  listDisplay,
  listPerPage,
  fieldsMapping,
  sortField,
  sortDirection,
  actions,
  bulkActions,
  noSettings,
}) => {
  const { t } = useTranslation()
  // bulk
  const [selectedBulk, setSelectedBulk] = React.useState(null)
  const executeBulkAction = () => {
    const action = R.find(R.propEq('id', selectedBulk))(bulkActions)
    return action && action.cb()
  }
  // columns
  const createColumnsProps = () =>
    columns
      .map((column) => ({
        ...column,
        visible: listDisplay.indexOf(column.id) !== -1,
      }))
      .sort((a, b) => {
        if (listDisplay.indexOf(a.id) !== -1 && listDisplay.indexOf(b.id) !== -1) {
          return listDisplay.indexOf(a.id) - listDisplay.indexOf(b.id)
        } else if (listDisplay.indexOf(a.id) !== -1) {
          return -1
        } else {
          return 1
        }
      })

  const [columnsProps, setColumnProps] = React.useState(
    R.ifElse(R.isNil, createColumnsProps, R.identity)(storageService.get(`${name}_CHANGELIST_TABLE_SETTINGS`)),
  )

  const handleSelectColumn = (id) => (event) => {
    setColumnProps(
      columnsProps.map((column) =>
        R.ifElse(
          R.equals(id),
          R.always({ ...column, visible: R.prop('checked')(event.target) }),
          R.always(column),
        )(column.id),
      ),
    )
  }

  const handleSortColumns = setColumnProps

  const saveSettings = () => {
    storageService.save(`${name}_CHANGELIST_TABLE_SETTINGS`, columnsProps)
    setSettingsDialogIsOpen(false)
  }

  // columns to be displayed
  const displayColumns = columnsProps.filter((c) => c.visible)

  // settings
  const [settingsDialogIsOpen, setSettingsDialogIsOpen] = React.useState(false)

  // page selection
  const handleSelectPage = (event) =>
    onSelect(R.ifElse(R.prop('checked'), R.always(displayData.map((r) => r)), R.always([]))(event.target))

  // record selection
  const handleSelectRecord = (record) => (event) =>
    onSelect(
      R.ifElse(
        R.prop('checked'),
        R.always([...selected, record]),
        R.always(selected.filter(R.compose(R.not, R.equals(record[idField]), R.prop(idField)))),
      )(event.target),
    )

  // all items selection
  const handleSelectAll = () => onSelect(data.map((record) => record))

  // clear selection
  const handleClearSelection = () => onSelect([])

  // sorting
  const [sort, setSort] = React.useState({ field: sortField, direction: sortDirection })
  const handleSortChange = (field) => () =>
    setSort({ field, direction: sort.field === field && sort.direction === 'asc' ? 'desc' : 'asc' })

  // pagination
  const [page, setPage] = React.useState(0)

  const getDisplayValue = (record, field, column) => {
    return fieldsMapping[field] ? fieldsMapping[field](record) : getValue(record, field, column)
  }

  // get value from record and field path
  const getValue = (record, field, column = null) => {
    const raw = field.split('.').reduce((acc, curr) => (acc ? acc[curr] : null), record)
    if (column && column.datetime) return raw ? moment(raw).format('Y-MM-DD HH:mm') : null
    if (column && column.boolean) {
      return raw ? <TrueIcon style={{ color: 'green' }} /> : <FalseIcon style={{ color: 'red' }} />
    }
    return raw
  }

  // sort and paginate
  // sort
  const displayData = [...data]
    .sort((a, b) => {
      return R.ifElse(R.isNil, R.always(1), () =>
        R.ifElse(
          R.isNil,
          R.always(-1),
          () => (getValue(b, sort.field) < getValue(a, sort.field) ? 1 : -1) * (sort.direction === 'asc' ? 1 : -1),
        )(getValue(b, sort.field)),
      )(getValue(a, sort.field))
    })
    // pagination
    .slice(page * listPerPage, (page + 1) * listPerPage)

  return (
    <>
      {!!bulkActions.length && (
        <BulkActions>
          <Dropdown
            label={t('common:generic.Commands')}
            width="300px"
            value={selectedBulk}
            onChange={setStateFromEvent(setSelectedBulk)}
            options={bulkActions.map((a) => ({ value: a.id, label: a.label, icon: a.icon }))}
          />
          <Button size="small" disabled={!selectedBulk || !selected.length} onClick={executeBulkAction}>
            {t('common:generic.Go')}
          </Button>
        </BulkActions>
      )}
      {(selectable || !noSettings) && (
        <Toolbar>
          <FlexRow alignCenter>
            {selectable && (
              <Typography component="div">
                {t('common:ui:numSelected', { num: selected.length, tot: data.length })}
              </Typography>
            )}
            {selectable && selected.length === displayData.length && selected.length !== data.length && (
              <SelectAll component="div">
                <SelectAllLink onClick={handleSelectAll}>{t('common:ui:selectAll')}</SelectAllLink>
              </SelectAll>
            )}
            {selectable && selected.length === data.length && (
              <SelectAll component="div">
                <SelectAllLink onClick={handleClearSelection}>{t('common:ui:clearSelection')}</SelectAllLink>
              </SelectAll>
            )}
          </FlexRow>
          {!noSettings && (
            <SettingsIcon
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={() => setSettingsDialogIsOpen(true)}
            />
          )}
        </Toolbar>
      )}
      <TableContainer style={{ maxWidth: '100%', overflow: 'auto' }}>
        <Table style={autoWidth ? { width: 'auto' } : {}} size="small">
          <TableHead>
            <TableRow style={{ background: '#f3f3f3' }}>
              {selectable && (
                <TableCell padding="checkbox" style={{ width: '20px' }}>
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < displayData.count}
                    checked={
                      displayData.length !== 0 &&
                      (selected.length === displayData.length || selected.length === data.length)
                    }
                    onChange={handleSelectPage}
                  />
                </TableCell>
              )}
              {displayColumns.map((column) => {
                return (
                  <TableCell
                    key={`col-${column.id}`}
                    align={column.numeric ? 'right' : 'left'}
                    sortDirection={sort.field === column.id ? sort.direction : false}
                    style={{ width: column.width ? column.width : 'auto' }}
                  >
                    <TableSortLabel
                      active={sort.field === column.id}
                      direction={sort.field === column.id ? sort.direction : 'asc'}
                      onClick={handleSortChange(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                )
              })}
              {actions && actions.length && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((record, index) => {
              return (
                <TableRow key={`row-${index}`} style={{ background: '#fff' }}>
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.filter(R.compose(R.equals(record[idField]), R.prop(idField))).length === 1}
                        onChange={handleSelectRecord(record)}
                      />
                    </TableCell>
                  )}
                  {displayColumns.map((column) => {
                    return (
                      <TableCell key={`col-${column.id}`} align={column.numeric ? 'right' : 'left'}>
                        {getDisplayValue(record, column.id, column)}
                      </TableCell>
                    )
                  })}
                  {actions && actions.length && (
                    <TableCell align="right">
                      <ActionsButton actions={actions} onClick={(action) => action.cb(record)} />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[listPerPage]}
        component="div"
        count={data.length}
        rowsPerPage={listPerPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
      />
      {settingsDialogIsOpen && (
        <SettingsDialog
          columns={columnsProps}
          open={settingsDialogIsOpen}
          handleClose={() => setSettingsDialogIsOpen(false)}
          handleSaveAndClose={saveSettings}
          onSelect={handleSelectColumn}
          onSort={handleSortColumns}
        />
      )}
    </>
  )
}

ChangeListTable.defaultProps = {
  idField: 'id',
  autoWidth: false,
  sortDirection: 'asc',
  listPerPage: 10,
  fieldsMapping: {},
  bulkActions: [],
}

ChangeListTable.propTypes = {
  name: PropTypes.string.isRequired,
  autoWidth: PropTypes.bool,
  idField: PropTypes.string,
  data: PropTypes.array.isRequired,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  listDisplay: PropTypes.array.isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string,
  listPerPage: PropTypes.number,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      cb: PropTypes.func.isRequired,
      icon: PropTypes.node,
    }),
  ),
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      cb: PropTypes.func.isRequired,
      icon: PropTypes.node,
    }),
  ),
  noSettings: PropTypes.bool,
  fieldsMapping: PropTypes.object,
}

export default ChangeListTable
