import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const engineTypesSlice = createSlice(apiSlice('engineTypes', []))

export const { request, success, failure } = engineTypesSlice.actions

export default engineTypesSlice.reducer

export const selectEngineTypes = (state) => state.engineTypes
export const selectEngineTypesData = (state) => state.engineTypes.data

// side effects
export const fetchEngineTypesEpic = (action$) =>
  action$.pipe(
    ofType('engineTypes/request'),
    mergeMap(() =>
      from(api.engineTypes.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchEngineTypesError',
        ),
      ),
    ),
  )
