import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as resourcesTypesRequest, selectResourcesTypesData } from '../Redux/ResourcesTypes'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const ResourceTypeDropdown = ({ value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const resourcesTypes = useSelector(selectResourcesTypesData)

  return (
    <Dropdown
      label={t('resources:fields.ecuDataFileType.__field')}
      required={required}
      value={value}
      onChange={onChange}
      options={resourcesTypes.length ? resourcesTypes.map((item) => ({ value: item.id, label: item.code })) : undefined}
      requestAction={resourcesTypesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

ResourceTypeDropdown.defaultProps = {
  required: false,
}

ResourceTypeDropdown.propTypes = FieldType

export default ResourceTypeDropdown
