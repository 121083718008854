import React from 'react'
// import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import history from '../../Core/history'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../Common/Components/PrivateRoute'
// Views
import MainView from '../Views/MainView'

/**
 * This component defines all the application routes, public and private ones.
 * Implement the right shouldDispatch functions!
 */
const DevicesRouter = () => {
  const { path } = useRouteMatch()

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute exact path={`${path}/`} component={MainView} />
      </Switch>
    </ConnectedRouter>
  )
}

export default DevicesRouter
