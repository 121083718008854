import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"	 viewBox="0 0 48 48" style={style}><path fill={color} d="M34,2L14,2c-2.2,0-4,1.8-4,4v36c0,2.2,1.8,4,4,4h20c2.2,0,4-1.8,4-4V6C38,3.8,36.2,2,34,2z M34,38H14V10h20V38z	 M32,26h-6V16h-4v10h-6l8,8L32,26z"/></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
