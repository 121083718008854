import { createSlice } from '@reduxjs/toolkit'
import { request as usersRequest } from '../../Auth/Redux/Users'
import { request as rolesRequest } from '../../Auth/Redux/Roles'
import { request as segmentsRequest } from './Segments'
import { request as productLinesRequest } from './ProductLines'
import { request as platformsRequest } from './Platforms'
import { request as programsRequest } from './Programs'
import { request as gpdPhasesRequest } from './GpdPhases'
import { request as sitesRequest } from './Sites'
import { request as domainsRequest } from './Domains'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

export const STARTUP_COMPLETED = 'startup/completed'

// reducer
const startupSlice = createSlice({
  name: 'startup',
  initialState: {
    initing: true,
    fetching: false,
  },
  reducers: {
    init: (state) => {
      return { ...state, initing: true }
    },
    initCompleted: (state) => {
      return { ...state, initing: false }
    },
    prefetch: (state) => {
      return { ...state, fetching: true }
    },
    prefetchCompleted: (state) => {
      return { ...state, fetching: false }
    },
  },
})

export const { init, initCompleted, prefetch, prefetchCompleted } = startupSlice.actions

export default startupSlice.reducer

export const selectStartup = (state) => state.startup
export const selectStartupIniting = (state) => state.startup.initing

// side effects
export const fetchStartupDataEpic = (action$) =>
  action$.pipe(
    ofType('startup/prefetch'),
    mergeMap(() => [
      usersRequest(),
      rolesRequest(),
      segmentsRequest(),
      productLinesRequest(),
      platformsRequest(),
      programsRequest(),
      gpdPhasesRequest(),
      sitesRequest(),
      domainsRequest(),
      prefetchCompleted(), // @TODO maybe just complete after all requests have finished
    ]),
  )
