import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const emissionLevelsSlice = createSlice(apiSlice('emissionLevels', []))

export const { request, success, failure } = emissionLevelsSlice.actions

export default emissionLevelsSlice.reducer

export const selectEmissionLevels = (state) => state.emissionLevels
export const selectEmissionLevelsData = (state) => state.emissionLevels.data

// side effects
export const fetchEmissionLevelsEpic = (action$) =>
  action$.pipe(
    ofType('emissionLevels/request'),
    mergeMap(() =>
      from(api.emissionLevels.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchEmissionLevelsError',
        ),
      ),
    ),
  )
