import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg viewBox="0 0 53 60" version="1.1" style={style} xmlns="http://www.w3.org/2000/svg"><g transform="matrix(1,0,0,1,-502,-400)"><g id="stored_data_icon" transform="matrix(0.99034,0,0,1,-48.097,-2.122)"><path d="M600.308,453.984L597.485,453.984L597.485,417.237L600.308,417.237L600.308,453.984ZM579.395,453.716L579.395,438.169L573.741,438.169L585.048,416.969L585.048,432.516L590.701,432.516L579.395,453.716ZM566.958,453.984L564.135,453.984L564.135,417.237L566.958,417.237L566.958,453.984ZM603.033,408.605L598.45,408.605L598.45,402.122L565.993,402.122L565.993,408.605L561.409,408.605C558.124,408.605 555.493,411.266 555.493,414.551L555.463,456.175C555.463,459.461 558.124,462.122 561.409,462.122L603.033,462.122C606.319,462.122 608.98,459.461 608.98,456.175L608.98,414.551C608.98,411.266 606.319,408.605 603.033,408.605" style={{ fill: color, fillRule: 'nonzero'}} /></g></g></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
