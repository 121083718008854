import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as sitesRequest, selectSitesData } from '../../Core/Redux/Sites'
import { FieldType } from '../Types/Field'
import Dropdown from './Dropdown'

const SiteDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const sites = useSelector(selectSitesData)

  return (
    <Dropdown
      label={label || t('common:fields.site')}
      required={required}
      value={value}
      onChange={onChange}
      options={sites.length ? sites.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={sitesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

SiteDropdown.defaultProps = {
  required: false,
}

SiteDropdown.propTypes = FieldType

export default SiteDropdown
