import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// namespaces
import i18nCore from './Core/Locales/i18n.json'
import i18nCommon from './Common/Locales/i18n.json'
import i18nAuth from './Auth/Locales/i18n.json'
import i18nVehicles from './Vehicles/Locales/i18n.json'
import i18nDevices from './Devices/Locales/i18n.json'
import i18nResources from './Resources/Locales/i18n.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    core: i18nCore,
    common: i18nCommon,
    auth: i18nAuth,
    vehicles: i18nVehicles,
    devices: i18nDevices,
    resources: i18nResources,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // language to use, you can use the i18n.changeLanguage function
    // to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    lng: 'en',
    defaultNS: 'core',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
