import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as networkRequest, selectNetworksData } from '../Redux/Networks'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const NetworkDropdown = ({ value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const network = useSelector(selectNetworksData)

  return (
    <Dropdown
      label={t('devices:fields.network.__field')}
      required={required}
      value={value}
      onChange={onChange}
      options={network.length ? network.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={networkRequest}
      error={error}
      helperText={helperText}
    />
  )
}

NetworkDropdown.defaultProps = {
  required: false,
}

NetworkDropdown.propTypes = FieldType

export default NetworkDropdown
