import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const vehiclesFleetsSlice = createSlice(apiSlice('vehiclesFleets', []))

export const { request, success, failure } = vehiclesFleetsSlice.actions

export default vehiclesFleetsSlice.reducer

export const selectVehiclesFleets = (state) => state.vehiclesFleets
export const selectVehiclesFleetsData = (state) => state.vehiclesFleets.data

// side effects
export const fetchVehiclesFleetsEpic = (action$) =>
  action$.pipe(
    ofType('vehiclesFleets/request'),
    mergeMap(() =>
      from(api.vehiclesFleets.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'vehicles:errors.FetchVehiclesFleetsError',
        ),
      ),
    ),
  )
