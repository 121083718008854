import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  console.log(color)
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.82 127.57" style={style}><defs></defs><g id="Livello_2" data-name="Livello 2"><g id="Livello_3" data-name="Livello 3"><path fill={color} d="M16.83,46.15A16.93,16.93,0,1,0,33.65,63.07,16.88,16.88,0,0,0,16.83,46.15Zm0,25.89a9,9,0,1,1,8.91-9A8.94,8.94,0,0,1,16.83,72Z"/><path fill={color} d="M16.83,59.09a4,4,0,1,0,4,4A4,4,0,0,0,16.83,59.09Z"/><path fill={color} d="M33.89,25.72l7.35-1.1C42.08,4.37,33.9,0,33.9,0l-2,2C39.05,16,33.89,25.72,33.89,25.72Z"/><path fill={color} d="M78.16,37.72A21.82,21.82,0,0,0,65,65.61Z"/><path fill={color} d="M80.71,32.31l5.16-10.92c-.79,0-1.61,0-2.46,0L80.89,3.66s-24.61-8.45-31.33,7l-.83,16.67S16.44,27.27,8.88,43c0,0,26.63-5.74,28.77,19.72H59.36S58.48,35.87,80.71,32.31ZM53.2,32.05,53.58,13S57.39,2.24,77.26,7.31l2.24,16S61.68,24.48,57.85,39.1Z"/><rect fill={color} x="16.6" y="56.95" width="128.47" height="8.48" rx="4.24" transform="translate(-8.99 108.2) rotate(-64.75)"/><path fill={color} d="M104.07,94.11a16.73,16.73,0,1,0,16.73,16.73A16.73,16.73,0,0,0,104.07,94.11Zm0,25.29a8.56,8.56,0,1,1,8.56-8.56A8.56,8.56,0,0,1,104.07,119.4Z"/><path fill={color} d="M104.07,109a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,104.07,109Z"/><path fill={color} d="M164.44,47.57,100,49.15,70.35,112l14.13-.55c0-.21,0-.42,0-.63a19.6,19.6,0,0,1,31.92-15.28h9.45l4.84,4.94h9L141,99.25h4.42l1.36-6.84-5.67-2.83s-2.32-9.78,0-10,2.1-1.58,2.1-1.58a62.72,62.72,0,0,0-2-13.43c-1.89-6.94-6.61-6.84-6.61-6.84l-27.92.67L105,55.78l48-1.18,5.68,8.28,6.15-.15Z"/></g></g></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
