import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as emissionLevelsRequest, selectEmissionLevelsData } from '../Redux/EmissionLevels'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const EmissionLevelDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const emissionLevels = useSelector(selectEmissionLevelsData)

  return (
    <Dropdown
      label={label || t('vehicles:fields.emissionLevel')}
      required={required}
      value={value}
      onChange={onChange}
      options={emissionLevels.length ? emissionLevels.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={emissionLevelsRequest}
      error={error}
      helperText={helperText}
    />
  )
}

EmissionLevelDropdown.defaultProps = {
  required: false,
}

EmissionLevelDropdown.propTypes = FieldType

export default EmissionLevelDropdown
