import React from 'react'
import AdminResourcesView from './Views/AdminResourcesView'

const ResourcesApp = () => {
  return (
    <AdminResourcesView />
  )
}

export default ResourcesApp
