import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const resourcesSlice = createSlice(apiSlice('resources', []))

export const { request, success, failure } = resourcesSlice.actions

export default resourcesSlice.reducer

export const selectResources = (state) => state.resources
export const selectResourcesData = (state) => state.resources.data

// side effects
export const fetchResourcesEpic = (action$) =>
  action$.pipe(
    ofType('resources/request'),
    mergeMap(() =>
      from(api.resources.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'resources:errors.FetchResourcesError',
        ),
      ),
    ),
  )
