import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RoleDropdown from '../../Components/RoleDropdown'
import SegmentDropdown from '../../../Common/Components/SegmentDropdown'
import DomainDropdown from '../../../Common/Components/DomainDropdown'
import { setStateFromEvent } from '../../../Common/Utils/Events'
import Button from '@mui/material/Button'
import TextField from '../../../Common/Components/TextField'
import Checkbox from '../../../Common/Components/Checkbox'
import { UserType } from '../../Types/User'
import logger from '../../../Common/Utils/Logger'
import { validate } from './Validation'
import { FormRow, FormColControl } from '../../../Common/Components/Forms'
import { propOrDefault } from '../../../Common/Utils/Objects'
import { setValidationErrors } from '../../../Common/Utils/Api'

const UserForm = ({ onClose, onSave, user }) => {
  const { t } = useTranslation()

  const [fields, setFields] = React.useState({
    email: propOrDefault(user, 'email'),
    isEnabled: propOrDefault(user, 'isEnabled'),
    name: propOrDefault(user, 'name'),
    surname: propOrDefault(user, 'surname'),
    roleId: propOrDefault(user, 'roleId'),
    segmentId: propOrDefault(user, 'segmentId'),
    domainId: propOrDefault(user, 'domainId'),
    accountName: propOrDefault(user, 'accountName'),
  })
  const [errors, setErrors] = React.useState({})

  const setField = (field) => (value) => {
    setFields({ ...fields, [field]: value })
  }

  const submit = async () => {
    logger.debug('User form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('User form submission, validation passed, saving')
      const { response, isSuccessful } = await onSave(fields)
      console.log('RESPONSE', response)
      if (!isSuccessful) {
        setValidationErrors(response, setErrors)
      }
    } else {
      logger.debug('User form submission, validation failed')
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t(`auth:ui.${user ? 'Edit' : 'Add'}User`)}</DialogTitle>
      <DialogContent>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('auth:fields.email')}
              value={fields.email}
              onChange={setStateFromEvent(setField('email'))}
              error={!!errors.email}
              helperText={errors.email}
            />
          </FormColControl>
          <FormColControl noFullChild>
            <Checkbox
              label={t('auth:fields.isEnabled')}
              style={{ position: 'relative', top: '20px' }}
              checked={fields.isEnabled}
              onChange={(event) => setField('isEnabled')(event.target.checked)}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('auth:fields.name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              required
              label={t('auth:fields.surname')}
              value={fields.surname}
              onChange={setStateFromEvent(setField('surname'))}
              error={!!errors.surname}
              helperText={errors.surname}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <SegmentDropdown
              required
              value={fields.segmentId}
              onChange={setStateFromEvent(setField('segmentId'))}
              error={!!errors.segmentId}
              helperText={errors.segmentId}
            />
          </FormColControl>
          <FormColControl>
            <RoleDropdown
              required
              value={fields.roleId}
              onChange={setStateFromEvent(setField('roleId'))}
              error={!!errors.roleId}
              helperText={errors.roleId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <DomainDropdown
              required
              value={fields.domainId}
              onChange={setStateFromEvent(setField('domainId'))}
              error={!!errors.domainId}
              helperText={errors.domainId}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              required
              label={t('auth:fields.accountName')}
              value={fields.accountName}
              onChange={setStateFromEvent(setField('accountName'))}
              error={!!errors.accountName}
              helperText={errors.accountName}
            />
          </FormColControl>
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('common:buttons.Cancel')}
        </Button>
        <Button onClick={submit} color="primary">
          {t('common:buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  user: UserType,
}

export default UserForm
