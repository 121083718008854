export const columns = (t) => [
  {
    id: 'id',
    label: t('auth:fields.id'),
    numeric: true,
    width: '40px',
  },
  {
    id: 'accountName',
    label: t('auth:fields.accountName'),
  },
  {
    id: 'createdBy',
    label: t('auth:fields.createdBy'),
  },
  {
    id: 'createdOn',
    label: t('auth:fields.createdOn'),
    datetime: true,
  },
  {
    id: 'domain.code',
    label: t('auth:fields.domain.code'),
  },
  {
    id: 'domain.description',
    label: t('auth:fields.domain.description'),
  },
  {
    id: 'email',
    label: t('auth:fields.email'),
  },
  {
    id: 'isEnabled',
    label: t('auth:fields.isEnabled'),
    boolean: true,
  },
  {
    id: 'role.code',
    label: t('auth:fields.role.code'),
  },
  {
    id: 'role.description',
    label: t('auth:fields.role.description'),
  },
  {
    id: 'segment.code',
    label: t('auth:fields.segment.code'),
  },
  {
    id: 'segment.description',
    label: t('auth:fields.segment.description'),
  },
  {
    id: 'surname',
    label: t('auth:fields.surname'),
  },
  {
    id: 'updatedBy',
    label: t('auth:fields.updatedBy'),
  },
  {
    id: 'updatedOn',
    label: t('auth:fields.updatedOn'),
    datetime: true,
  },
]
