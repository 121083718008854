import { configureStore } from '@reduxjs/toolkit'
import { RootReducer, RootEpic } from './Root'
import { createEpicMiddleware } from 'redux-observable'

// side effects
const epicMiddleware = createEpicMiddleware()

const store = configureStore({
  reducer: RootReducer,
  middleware: [epicMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})

epicMiddleware.run(RootEpic)

export default store
