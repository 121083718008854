import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { request as resourcesJobsRequest } from '../Redux/ResourcesJobs'
import { useTranslation } from 'react-i18next'
import { ResourceJobType } from '../Types/ResourceJob'
import { columns } from '../Models/ResourceJob'
import ChangeListTable from '../../Common/Components/ChangeListTable'
import OkIcon from '@mui/icons-material/Done'
import KoIcon from '@mui/icons-material/Clear'
import Progress from '../../Common/Components/Progress'
import Tooltip from '@mui/material/Tooltip'
import Loader from '../../Common/Components/Loader'
import { request } from '../../Common/Utils/Api'
import api from '../../Core/Services/Api'
import styled from 'styled-components'
import * as R from 'ramda'
import { useConfirm } from '../../Common/Utils/Hooks'

const Container = styled.div`
  width: 100%;
`

const Null = () => null
const Ok = () => <OkIcon style={{ color: 'green' }} />
const Ko = (job) => (
  <Tooltip title={job.errorDescription}>
    <KoIcon style={{ color: 'red' }} />
  </Tooltip>
)

const renderImportProgress = (job) => {
  const Prog = (job) => <Progress variant="determinate" value={job.importProgress} />
  // return R.ifElse(R.prop('hasError'), Null, Prog)(job)
  return Prog(job)
}

const renderStatus = (job) => {
  return R.ifElse(
    R.propEq('importProgress', 100),
    Ok,
    R.ifElse(R.prop('hasError'), Ko, Null),
  )(job)
}

const ResourcesList = ({ resourcesJobs, loading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selected, setSelected] = React.useState([])

  const handleDeleteJobs = async () => {
    closeDeleteConfirm()
    const promises = selected.map(async (job) => {
      const response = await request(
        api.resourcesJobs.delete,
        [job.id],
        t('resources:success.ResourceJobDeleted', {
          resourceJob: job.id,
        }),
        'resources:errors.DeleteResourceJobError',
      )
      return response
    })
    await Promise.all(promises)
    setSelected([])
    dispatch(resourcesJobsRequest())
  }

  const [openDeleteConfirm, closeDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteJobs)
  const bulkActions = [
    {
      id: 'DELETE',
      label: t('resources:ui.DeleteSelectedResourcesJobs'),
      cb: () =>
        openDeleteConfirm(
          null, // payload
          t('resources:ui.DeleteResourcesJobsConfirmationTitle'),
          t('resources:ui.DeleteResourcesConfirmationText'),
        ),
    },
  ]

  return (
    <Container>
      {loading && <Loader minHeight="200px" />}
      {!loading && (
        <ChangeListTable
          name="resourcesJobs"
          selectable
          data={resourcesJobs}
          columns={columns(t)}
          sortField="id"
          sortDirection="desc"
          listDisplay={[
            'id',
            'ecuDataFile.fileName',
            'ecuDataFile.ecuDataFileType.code',
            'startedOn',
            'finishedOn',
            'importProgress',
            'status',
          ]}
          bulkActions={bulkActions}
          fieldsMapping={{ importProgress: renderImportProgress, status: renderStatus }}
          selected={selected}
          onSelect={setSelected}
        />
      )}
      {DeleteConfirm}
    </Container>
  )
}

ResourcesList.propTypes = {
  resourcesJobs: PropTypes.arrayOf(ResourceJobType),
  loading: PropTypes.bool,
}

export default ResourcesList
