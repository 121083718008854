import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as vehicleStatusesRequest, selectVehicleStatusesData } from '../Redux/VehicleStatuses'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const VehicleStatusDropdown = ({ value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const vehicleStatuses = useSelector(selectVehicleStatusesData)

  return (
    <Dropdown
      label={t('vehicles:fields.vehicleStatus')}
      required={required}
      value={value}
      onChange={onChange}
      options={vehicleStatuses.length ? vehicleStatuses.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={vehicleStatusesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

VehicleStatusDropdown.defaultProps = {
  required: false,
}

VehicleStatusDropdown.propTypes = FieldType

export default VehicleStatusDropdown
