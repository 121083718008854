import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './i18n'
import './index.css'
import App from './Core/App'
import store from './Core/Redux/Store'
import reportWebVitals from './reportWebVitals'
import logger from './Common/Utils/Logger'
import { authenticate } from './Auth/Services'
import { init, initCompleted, prefetch } from './Core/Redux/Startup'

// Theme
import { ThemeProvider } from '@mui/material/styles'
import theme from './Theme'

// MSAL imports
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './Auth/Config'

logger.info('Intializing Prometheus UI Application')

logger.info('Checking authentication and triggering startup')
export const msalInstance = new PublicClientApplication(msalConfig)
store.dispatch(init())
authenticate(
  msalInstance,
  store.dispatch,
  () => store.dispatch(initCompleted()),
  () => store.dispatch(prefetch()),
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
