import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FieldType } from '../Types/Field'
import FormControl from '@mui/material/FormControl'
import TextField from './TextField'
import MuiAutocomplete from '@mui/material/Autocomplete'
import logger from '../Utils/Logger'

const Autocomplete = ({ label, value, onChange, required, freeSolo, options, requestAction, error, helperText }) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (options === undefined) {
      logger.debug(`Autocomplete ${label}, undefined options, dispatching action`, requestAction())
      dispatch(requestAction())
    }
  }, [])

  return (
    <FormControl style={{ width: '100%' }}>
      <MuiAutocomplete
        freeSolo={freeSolo}
        value={value}
        onChange={(_, option) => onChange(option ? option.value : null)}
        style={{ width: '100%' }}
        getOptionLabel={option => option.label || ''}
        options={options}
        renderInput={(params) => (
          <TextField {...params} label={label} required={required} error={error} helperText={helperText} />
        )}
      />
    </FormControl>
  )
}

Autocomplete.propTypes = {
  ...FieldType,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })),
  requestAction: PropTypes.func,
  freeSolo: PropTypes.bool,
}

export default Autocomplete
