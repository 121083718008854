export default class Vehicle {
  constructor (data) {
    this.data = data
  }
}

export const columns = (t) => [
  {
    id: 'id',
    label: t('vehicles:fields.id'),
    numeric: true,
    width: '40px',
  },
  {
    id: 'createdBy',
    label: t('vehicles:fields.createdBy'),
  },
  {
    id: 'createdOn',
    label: t('vehicles:fields.createdOn'),
    datetime: true,
  },
  {
    id: 'updatedBy',
    label: t('vehicles:fields.updatedBy'),
  },
  {
    id: 'updatedOn',
    label: t('vehicles:fields.updatedOn'),
    datetime: true,
  },
  {
    id: 'description',
    label: t('vehicles:fields.description'),
  },
  {
    id: 'vehicleStatusId',
    label: t('vehicles:fields.vehicleStatusId'),
  },
  {
    id: 'modelId',
    label: t('vehicles:fields.modelId'),
  },
  {
    id: 'model.description',
    label: t('vehicles:fields.model.description'),
  },
  {
    id: 'model.createdBy',
    label: t('vehicles:fields.model.createdBy'),
  },
  {
    id: 'model.createdOn',
    label: t('vehicles:fields.model.createdOn'),
    datetime: true,
  },
  {
    id: 'device.serialNumber',
    label: t('vehicles:fields.device.serialNumber'),
  },
  {
    id: 'device.description',
    label: t('vehicles:fields.device.description'),
  },
  {
    id: 'device.imei',
    label: t('vehicles:fields.device.imei'),
  },
  {
    id: 'device.bundleVersion',
    label: t('vehicles:fields.device.bundleVersion'),
  },
  {
    id: 'device.activated',
    label: t('vehicles:fields.device.activated'),
  },
  {
    id: 'plateNumber',
    label: t('vehicles:fields.plateNumber'),
  },
  {
    id: 'serialNumber',
    label: t('vehicles:fields.serialNumber'),
  },
  {
    id: 'segment.code',
    label: t('vehicles:fields.segment.code'),
  },
  {
    id: 'segment.description',
    label: t('vehicles:fields.segment.description'),
  },
  {
    id: 'platform.externalCode',
    label: t('vehicles:fields.platform.externalCode'),
  },
  {
    id: 'platform.description',
    label: t('vehicles:fields.platform.description'),
  },
  {
    id: 'productLineId',
    label: t('vehicles:fields.productLineId'),
  },
  {
    id: 'program.description',
    label: t('vehicles:fields.program.description'),
  },
  {
    id: 'program.createdBy',
    label: t('vehicles:fields.program.createdBy'),
  },
  {
    id: 'program.createdOn',
    label: t('vehicles:fields.program.createdOn'),
    datetime: true,
  },
  {
    id: 'notes',
    label: t('vehicles:fields.notes'),
  },
  {
    id: 'ownerId',
    label: t('vehicles:fields.ownerId'),
  },
  {
    id: 'engineType.code',
    label: t('vehicles:fields.engineType.code'),
  },
  {
    id: 'engineType.description',
    label: t('vehicles:fields.engineType.description'),
  },
  {
    id: 'emissionLevelId',
    label: t('vehicles:fields.emissionLevelId'),
  },
  {
    id: 'ctsVehicleId',
    label: t('vehicles:fields.ctsVehicleId'),
  },
  {
    id: 'gpdPhaseId',
    label: t('vehicles:fields.gpdPhaseId'),
  },
  {
    id: 'pvResponsible',
    label: t('vehicles:fields.pvResponsible'),
  },
]
