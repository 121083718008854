import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const programsSlice = createSlice(apiSlice('programs', []))

export const { request, success, failure } = programsSlice.actions

export default programsSlice.reducer

export const selectPrograms = (state) => state.programs
export const selectProgramsData = (state) => state.programs.data

// side effects
export const fetchProgramsEpic = (action$) =>
  action$.pipe(
    ofType('programs/request'),
    mergeMap(() =>
      from(api.programs.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchProgramsError',
        ),
      ),
    ),
  )
