import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Draggable } from 'react-smooth-dnd'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DragIcon from '@mui/icons-material/DragIndicator'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as R from 'ramda'

const Row = styled.div`
  align-items: center;
  border: 1px solid #f3f3f3;
  border-bottom: 0px solid;
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  padding: 0;

  &:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
`

const SettingsDialog = ({ open, handleClose, handleSaveAndClose, columns, onSelect, onSort }) => {
  const { t } = useTranslation()
  const selected = columns.filter(R.prop('visible')).map(R.prop('id'))

  const handleDrop = ({ removedIndex, addedIndex }) => {
    const copy = [...columns]
    onSort(
      R.ifElse(
        R.isNil,
        R.always(columns),
        R.compose(R.always(copy), R.always(copy.splice(addedIndex, 0, copy.splice(removedIndex, 1)[0]))),
        R.always(columns),
      )(removedIndex),
    )
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('common:generic.Settings')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('common:ui:ColumnsSettings')}</DialogContentText>
        <Container onDrop={handleDrop}>
          {columns.map((column) => {
            return (
              <Draggable key={`row-${column.id}`}>
                <Row>
                  <div>
                    <Checkbox checked={selected.indexOf(column.id) !== -1} onChange={onSelect(column.id)} />
                    <span>{column.label}</span>
                  </div>
                  <DragIcon style={{ cursor: 'move' }} />
                </Row>
              </Draggable>
            )
          })}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common:buttons.Close')}
        </Button>
        <Button onClick={handleSaveAndClose} color="primary">
          {t('common:buttons.SaveAndClose')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SettingsDialog.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      visible: PropTypes.bool,
    }),
  ),
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSaveAndClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
}

export default SettingsDialog
