import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { request as programsRequest, selectProgramsData } from '../../Core/Redux/Programs'
import { FieldType } from '../Types/Field'
import Autocomplete from './Autocomplete'
import * as R from 'ramda'

// @TODO freeSolo and create program on blur
const ProgramField = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const programs = useSelector(selectProgramsData)
  const programsMap = React.useMemo(() => programs.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}), [programs])
  const getProgramLabel = p => p.description
  const getProgramOption = p => ({ value: p.id, label: getProgramLabel(p) })

  const val = R.ifElse(
    R.prop(value),
    R.pipe(R.prop(value), getProgramOption),
    R.always(null),
  )(programsMap)

  return (
    <Autocomplete
      label={label || t('common:fields.program')}
      required={required}
      value={val}
      onChange={onChange}
      options={programs.map(getProgramOption)}
      requestAction={programsRequest}
      error={error}
      helperText={helperText}
    />
  )
}

ProgramField.defaultProps = {
  required: false,
}

ProgramField.propTypes = FieldType

export default ProgramField
