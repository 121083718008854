import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as segmentsRequest, selectSegmentsData } from '../../Core/Redux/Segments'
import { FieldType } from '../Types/Field'
import Dropdown from './Dropdown'

const SegmentDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const segments = useSelector(selectSegmentsData)

  return (
    <Dropdown
      label={label || t('common:fields.segment')}
      required={required}
      value={value}
      onChange={onChange}
      options={segments.length ? segments.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={segmentsRequest}
      error={error}
      helperText={helperText}
    />
  )
}

SegmentDropdown.defaultProps = {
  required: false,
}

SegmentDropdown.propTypes = FieldType

export default SegmentDropdown
