import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: ${props => props.minHeight};
`

const Loader = ({ minHeight, size }) => {
  return (
    <Wrapper minHeight={minHeight}>
      <CircularProgress color='primary' size={size}></CircularProgress>
    </Wrapper>
  )
}

Loader.defaultProps = {
  minHeight: '0',
  size: 40,
}

Loader.propTypes = {
  minHeight: PropTypes.string,
  size: PropTypes.number,
}

export default Loader
