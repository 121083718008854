import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { request as usersRequest, selectUsersData } from '../Redux/Users'
import { FieldType } from '../../Common/Types/Field'
import Autocomplete from '../../Common/Components/Autocomplete'
import * as R from 'ramda'

const UserField = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const users = useSelector(selectUsersData)
  const usersMap = React.useMemo(() => users.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}), [users])
  const getUserLabel = u => `${u.name} ${u.surname} <${u.email}>`
  const getUserOption = u => ({ value: u.id, label: getUserLabel(u) })

  const val = R.ifElse(
    R.prop(value),
    R.pipe(R.prop(value), getUserOption),
    R.always(null),
  )(usersMap)

  return (
    <Autocomplete
      freeSolo
      label={label || t('auth:fields.user')}
      required={required}
      value={val}
      onChange={onChange}
      options={users.map(getUserOption)}
      requestAction={usersRequest}
      error={error}
      helperText={helperText}
    />
  )
}

UserField.defaultProps = {
  required: false,
}

UserField.propTypes = FieldType

export default UserField
