import PropTypes from 'prop-types'

export const DeviceType = PropTypes.shape({
  ownerId: PropTypes.number,
  id: PropTypes.number,
  serialNumber: PropTypes.string,
  description: PropTypes.string,
  deviceStatusId: PropTypes.number,
  deviceTypeId: PropTypes.number,
  simId: PropTypes.number,
  vehicleId: PropTypes.number,
  imei: PropTypes.string,
  networkId: PropTypes.number,
  siteId: PropTypes.number,
  segmentId: PropTypes.number,
  bundleVersion: PropTypes.string,
  manufacturingDate: PropTypes.string,
  activated: PropTypes.bool,
  pvResponsible: PropTypes.string,
  notes: PropTypes.string,
  createdBy: PropTypes.string,
  createdOn: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string,
})
