import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={style}><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path fill={color} d="M384,121.9V128H256V0h6.1a24,24,0,0,1,17,7L377,105A23.92,23.92,0,0,1,384,121.9ZM248,160a24.07,24.07,0,0,1-24-24V0H24A23.94,23.94,0,0,0,0,24V488a23.94,23.94,0,0,0,24,24H360a23.94,23.94,0,0,0,24-24V160ZM123.2,400.5a5.29,5.29,0,0,1-7.6.2h0L50.7,339.9a5.29,5.29,0,0,1-.2-7.6l.2-.2,64.9-60.8a5.46,5.46,0,0,1,7.6.2h0l19.6,20.9a5.46,5.46,0,0,1-.2,7.6l-.1.1L101.7,336l40.8,35.9a5.39,5.39,0,0,1,.5,7.6l-.1.1-19.7,20.9ZM174.5,451,147,443a5.39,5.39,0,0,1-3.7-6.7l61.4-211.6a5.39,5.39,0,0,1,6.7-3.7l27.5,8a5.39,5.39,0,0,1,3.7,6.7h0L181.2,447.3a5.39,5.39,0,0,1-6.7,3.7ZM335.3,339.9l-64.9,60.8a5.46,5.46,0,0,1-7.6-.2h0l-19.6-20.9a5.46,5.46,0,0,1,.2-7.6l.1-.1L284.3,336l-40.8-35.9a5.39,5.39,0,0,1-.5-7.6l.1-.1,19.6-20.9a5.29,5.29,0,0,1,7.6-.2h0l64.9,60.8a5.29,5.29,0,0,1,.2,7.6c.1.1,0,.2-.1.2Z"/></g></g></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
