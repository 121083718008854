import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const modelsSlice = createSlice(apiSlice('models', []))

export const { request, success, failure } = modelsSlice.actions

export default modelsSlice.reducer

export const selectModels = (state) => state.models
export const selectModelsData = (state) => state.models.data

// side effects
export const fetchModelsEpic = (action$) =>
  action$.pipe(
    ofType('models/request'),
    mergeMap(() =>
      from(api.models.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchModelsError',
        ),
      ),
    ),
  )
