import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107 80" style={style}><g id="Livello_2" data-name="Livello 2"><g id="Livello_3" data-name="Livello 3"><path fill={color} d="M82.86,32.05a34,34,0,0,1,23.74,7.51s2.83-18.74-23.19-18.18L80.89,3.66s-24.61-8.45-31.33,7l-.83,16.67S16.44,27.27,8.88,43c0,0,26.63-5.74,28.77,19.72H59.36S58.42,34.16,82.86,32.05Zm-29.66,0L53.58,13S57.39,2.24,77.26,7.31l2.24,16S61.68,24.48,57.85,39.1Z"/><path fill={color} d="M85.37,36.48A21.76,21.76,0,1,0,107,58.24,21.71,21.71,0,0,0,85.37,36.48Zm0,33.28A11.53,11.53,0,1,1,96.82,58.24,11.49,11.49,0,0,1,85.37,69.76Z"/><ellipse fill={color} cx="85.37" cy="58.24" rx="5.09" ry="5.12"/><path fill={color} d="M16.83,46.15A16.93,16.93,0,1,0,33.65,63.07,16.88,16.88,0,0,0,16.83,46.15Zm0,25.89a9,9,0,1,1,8.91-9A8.94,8.94,0,0,1,16.83,72Z"/><path fill={color} d="M16.83,59.09a4,4,0,1,0,4,4A4,4,0,0,0,16.83,59.09Z"/><path fill={color} d="M33.89,25.72l7.35-1.1C42.08,4.37,33.9,0,33.9,0l-2,2C39.05,16,33.89,25.72,33.89,25.72Z"/></g></g></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
