// a library to wrap and simplify api calls
import axios from 'axios'
import history from '../history'
import config from '../Config'
import logger from '../../Common/Utils/Logger'

const api = axios.create({
  baseURL: config.apiBasePath,
  // here are some default headers
  headers: {
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
  },
  timeout: 0,
})

// Add a response interceptor
api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data
    } else {
      // The request wasn't made or was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      logger.warning('Network error, redirecting to ', config.urls.networkError, error)
      setTimeout(() => history.push(config.urls.networkError), 0) // @TODO
    }
  },
)

export default {
  // Authentication stuff
  setAuthToken: (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  removeAuthToken: () => {
    delete api.defaults.headers.common.Authorization
  },
  // auth
  users: {
    get: () => api.get('domain/user'),
    delete: (id) => api.delete(`domain/user/${id}`),
    createOrUpdate: (user, id = null) => api[id ? 'patch' : 'post'](`domain/user${id ? '/' + id : ''}`, user),
  },
  roles: {
    get: () => api.get('domain/role'),
  },
  // common
  domains: {
    get: () => api.get('domain/domain'),
  },
  segments: {
    get: () => api.get('domain/segment'),
  },
  productLines: {
    get: () => api.get('domain/productline'),
  },
  platforms: {
    get: () => api.get('domain/platform'),
  },
  programs: {
    get: () => api.get('domain/program'),
  },
  gpdPhases: {
    get: () => api.get('domain/gpdphase'),
  },
  sites: {
    get: () => api.get('domain/site'),
  },
  // vehicles
  vehicles: {
    get: () => api.get('domain/vehicle'),
    create: (vehicle) => api.post('domain/vehicle', vehicle),
  },
  vehiclesFleets: {
    get: (id = null) => api.get(`domain/vehiclefleet${id ? '/' + id : ''}`),
    create: (fleet) => api.post('domain/vehiclefleet', fleet),
    update: (id, fleet) => api.post(`domain/vehiclefleet/${id}`, fleet),
    delete: (id) => api.delete(`domain/vehiclefleet/${id}`),
    createOrUpdate: (fleet, id = null) =>
      api[id ? 'patch' : 'post'](`domain/vehiclefleet${id ? '/' + id : ''}`, fleet),
  },
  vehicleStatuses: {
    get: () => api.get('domain/vehiclestatus'),
  },
  engineTypes: {
    get: () => api.get('domain/enginetype'),
  },
  emissionLevels: {
    get: () => api.get('domain/emissionlevel'),
  },
  models: {
    get: () => api.get('domain/model'),
  },
  // devices
  devices: {
    get: (id = null) => api.get(`domain/device${id ? '/' + id : ''}`),
  },
  devicesFleets: {
    get: (id = null) => api.get(`domain/devicefleet${id ? '/' + id : ''}`),
    create: (fleet) => api.post('domain/devicefleet', fleet),
    update: (id, fleet) => api.post(`domain/devicefleet/${id}`, fleet),
    createOrUpdate: (fleet, id = null) =>
      api[id ? 'patch' : 'post'](`domain/devicefleet${id ? '/' + id : ''}`, fleet),
    delete: (id) => api.delete(`domain/devicefleet/${id}`),
  },
  deviceStatuses: {
    get: () => api.get('domain/devicestatus'),
  },
  deviceTypes: {
    get: () => api.get('domain/devicetype'),
  },
  networks: {
    get: () => api.get('domain/network'),
  },
  // signalr
  signalr: {
    upload: () => api.post('resource/resource/messages'),
  },
  // resources
  resources: {
    get: () => api.get('resource/resource'),
    create: (formData, onProgress) => api.post('resource/resource', formData, { onUploadProgress: onProgress }),
    patch: (id, data) => api.patch(`resource/resource/${id}`, data),
    delete: (id) => api.delete(`resource/resource/${id}`),
  },
  resourcesTypes: {
    get: () => api.get('resource/resourcetype'),
  },
  resourcesJobs: {
    get: () => api.get('resource/resourcejob'),
    delete: (id) => api.delete(`resource/resourcejob/${id}`),
  },
}
