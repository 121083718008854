import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { connectRouter } from 'connected-react-router'
import history from '../history'

import { catchError } from 'rxjs/operators'
// reducers and epics
import startupReducer, { fetchStartupDataEpic } from '../Redux/Startup'
import segmentsReducer, { fetchSegmentsEpic } from './Segments'
import productLinesReducer, { fetchProductLinesEpic } from './ProductLines'
import platformsReducer, { fetchPlatformsEpic } from './Platforms'
import programsReducer, { fetchProgramsEpic } from './Programs'
import gpdPhasesReducer, { fetchGpdPhasesEpic } from './GpdPhases'
import sitesReducer, { fetchSitesEpic } from './Sites'
import domainsReducer, { fetchDomainsEpic } from './Domains'
import vehiclesReducer, { epics as vehicleEpics } from '../../Vehicles/Redux'
import devicesReducer, { epics as deviceEpics } from '../../Devices/Redux'
import authReducer, { epics as authEpics } from '../../Auth/Redux'
import resourcesReducer, { epics as resourcesEpics } from '../../Resources/Redux'

import logger from '../../Common/Utils/Logger'

// root reducer
export const RootReducer = combineReducers({
  router: connectRouter(history),
  startup: startupReducer,
  auth: authReducer,
  segments: segmentsReducer,
  productLines: productLinesReducer,
  platforms: platformsReducer,
  programs: programsReducer,
  gpdPhases: gpdPhasesReducer,
  sites: sitesReducer,
  domains: domainsReducer,
  ...authReducer,
  ...vehiclesReducer,
  ...devicesReducer,
  ...resourcesReducer,
})

// side effects
export const RootEpic = (action$, store$, dependencies) =>
  combineEpics(
    fetchStartupDataEpic,
    fetchSegmentsEpic,
    fetchProductLinesEpic,
    fetchPlatformsEpic,
    fetchProgramsEpic,
    fetchGpdPhasesEpic,
    fetchSitesEpic,
    fetchDomainsEpic,
    ...authEpics,
    ...vehicleEpics,
    ...deviceEpics,
    ...resourcesEpics,
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      logger.error(error)
      return source
    }),
  )
