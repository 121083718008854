import React from 'react'
import { useMsal } from '@azure/msal-react'
import { login } from '../../Services'
import { useTranslation } from 'react-i18next'
import { setStateFromEvent } from '../../../Common/Utils/Events'
import LogoImage from '../../../Assets/Images/logo-v.svg'
import Bg from './bg.mp4'
import FirstFrame from '../../../Assets/Images/first_frame.jpg'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import styled from 'styled-components'

const VideoBackground = styled.video`
  height: 100vh;
  left: 0;
  object-fit: cover;
  position: fixed;
  top: 0;
  width: 100vw;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

const Logo = styled.img`
  display: block;
  margin: auto; 
  margin-bottom: 2rem;
  width: 70%;
`

const Content = styled.div`
  background: #fff;
  margin-left: 4rem;
  padding: 2rem;
`

const Title = styled.h1`
  font-size: 1.2rem;
  text-align: center;
`

const Intro = styled.p`
  color: #666;
  font-size: .9rem;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const LoginView = () => {
  const { t } = useTranslation()
  const { instance: msalInstance } = useMsal()
  const [username, setUsername] = React.useState('')

  return (
    <>
      <VideoBackground autoPlay muted loop poster={FirstFrame}>
        <source src={Bg} type="video/mp4" />
      </VideoBackground>
      <Wrapper>
        <Content>
          <Logo src={LogoImage} />
          <Title>{t('auth:views.LoginTitle')}</Title>
          <Intro>{t('auth:views.LoginIntro')}</Intro>
          <TextField
            label={t('auth:fields.Username')}
            value={username}
            onChange={setStateFromEvent(setUsername)}
            style={{ width: '100%' }}
          />
          <ButtonRow>
            <Button variant="contained" color="primary" onClick={() => login(msalInstance, username)}>
              {t('auth:actions.login')}
            </Button>
          </ButtonRow>
        </Content>
      </Wrapper>
    </>
  )
}

export default LoginView
