import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"	 viewBox="0 0 1000 1000" style={style}><path fill={color} d="M353,377.5c0,31.9-20.5,58.4-49,68.5v299h-49V446c-28.4-10.1-49.1-36.6-49.1-68.5c0-32,20.7-58.5,49.1-68.6	v-103h49v103C332.5,319.1,353,345.6,353,377.5z M500,406.9v-201h-49v201c-28.4,10.2-49,36.7-49,68.6s20.5,58.4,49,68.6V745h49V544.1	c28.4-10.2,49.1-36.7,49.1-68.6S528.4,417.1,500,406.9L500,406.9z M990,205.9v588.2C990,902.3,902.3,990,794,990H205.9	C97.7,990,10,902.3,10,794.1V205.9C10,97.7,97.7,10,205.9,10H794C902.3,10,990,97.7,990,205.9z M892.1,255	c0-81.3-65.9-147.1-147.1-147.1H255c-81.2,0-147,65.8-147,147.1v490c0,81.3,65.8,147,147,147h490c81.2,0,147.1-65.8,147.1-147.1V255	z M745,504.9v-299h-49v299c-28.4,10.2-49,36.7-49,68.6c0,32,20.6,58.5,49,68.6V745h49V642.1c28.4-10.1,49-36.6,49-68.6	C794,541.6,773.4,515.1,745,504.9z"/></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
