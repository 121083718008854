import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../Auth/Redux/Auth'
import { selectUsersData } from '../../../Auth/Redux/Users'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SegmentDropdown from '../../../Common/Components/SegmentDropdown'
import DeviceStatusDropdown from '../../Components/DeviceStatusDropdown'
import DeviceTypeDropdown from '../../Components/DeviceTypeDropdown'
import NetworkDropdown from '../../Components/NetworkDropdown'
import SiteDropdown from '../../../Common/Components/SiteDropdown'
import DatePicker from '../../../Common/Components/DatePicker'
import UserField from '../../../Auth/Components/UserField'
import { setStateFromEvent } from '../../../Common/Utils/Events'
import Button from '@mui/material/Button'
import TextField from '../../../Common/Components/TextField'
import { DeviceType } from '../../Types/Device'
import * as R from 'ramda'
import logger from '../../../Common/Utils/Logger'
import { validate } from './Validation'
import { FormRow, FormColControl } from '../../../Common/Components/Forms'
import { propOrDefault } from '../../../Common/Utils/Objects'

const DeviceForm = ({ onClose, onSave, device }) => {
  const { t } = useTranslation()
  const authData = useSelector(selectAuth)
  const users = useSelector(selectUsersData)
  const authUserId = R.ifElse(
    R.isNil,
    R.always(null),
    R.prop('id'),
  )(R.find(R.propEq('email', authData.username), users))

  const [fields, setFields] = React.useState({
    serialNumber: propOrDefault(device, 'serialNumber'),
    deviceStatusId: propOrDefault(device, 'deviceStatusId'),
    description: propOrDefault(device, 'description'),
    imei: propOrDefault(device, 'imei'),
    deviceTypeId: propOrDefault(device, 'deviceTypeId'),
    networkId: propOrDefault(device, 'networkId'),
    manufacturingDate: propOrDefault(device, 'manufacturingDate'),
    segmentId: propOrDefault(device, 'segmentId'),
    pvResponsible: propOrDefault(device, 'pvResponsible'),
    siteId: propOrDefault(device, 'siteId'),
    ownerId: propOrDefault(device, 'ownerId', authUserId),
    notes: propOrDefault(device, 'notes'),
  })
  const [errors, setErrors] = React.useState({})

  const setField = (field) => (value) => {
    // @TODO validate on field change??
    // set value
    setFields({ ...fields, [field]: value })
  }

  const submit = () => {
    logger.debug('Device form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('Device form submission, validation passed, saving')
      onSave(fields)
    } else {
      logger.debug('Device form submission, validation failed')
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t(`devices:ui.${device ? 'Edit' : 'Add'}Device`)}</DialogTitle>
      <DialogContent>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('devices:fields.serialNumber')}
              value={fields.serialNumber}
              onChange={setStateFromEvent(setField('serialNumber'))}
              error={!!errors.serialNumber}
              helperText={errors.serialNumber}
            />
          </FormColControl>
          <FormColControl>
            <DeviceStatusDropdown
              value={fields.deviceStatusId}
              onChange={setStateFromEvent(setField('deviceStatusId'))}
              error={!!errors.deviceStatusId}
              helperText={errors.deviceStatusId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('devices:fields.description')}
              value={fields.description}
              onChange={setStateFromEvent(setField('description'))}
              error={!!errors.description}
              helperText={errors.description}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              required
              label={t('devices:fields.imei')}
              value={fields.imei}
              onChange={setStateFromEvent(setField('imei'))}
              error={!!errors.imei}
              helperText={errors.imei}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <DeviceTypeDropdown
              required
              value={fields.deviceTypeId}
              onChange={setStateFromEvent(setField('deviceTypeId'))}
              error={!!errors.deviceTypeId}
              helperText={errors.deviceTypeId}
            />
          </FormColControl>
          <FormColControl>
            <NetworkDropdown
              value={fields.network}
              onChange={setStateFromEvent(setField('network'))}
              error={!!errors.network}
              helperText={errors.network}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <DatePicker
              label={t('devices:fields.manufacturingDate')}
              value={fields.manufacturingDate}
              onChange={setField('manufacturingDate')}
              error={!!errors.manufacturingDate}
              helperText={errors.manufacturingDate}
            />
          </FormColControl>
          <FormColControl>
            <SegmentDropdown
              required
              value={fields.segmentId}
              onChange={setStateFromEvent(setField('segmentId'))}
              error={!!errors.segmentId}
              helperText={errors.segmentId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <TextField
              label={t('devices:fields.pvResponsible')}
              value={fields.pvResponsible}
              onChange={setStateFromEvent(setField('pvResponsible'))}
              error={!!errors.pvResponsible}
              helperText={errors.pvResponsible}
            />
          </FormColControl>
          <FormColControl>
            <SiteDropdown
              value={fields.siteId}
              onChange={setStateFromEvent(setField('siteId'))}
              error={!!errors.siteId}
              helperText={errors.siteId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <UserField
              required
              label={t('devices:fields.owner.__field')}
              value={fields.ownerId}
              onChange={setField('ownerId')}
              error={!!errors.ownerId}
              helperText={errors.ownerId}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              label={t('devices:fields.notes')}
              value={fields.notes}
              onChange={setStateFromEvent(setField('notes'))}
              error={!!errors.notes}
              helperText={errors.notes}
            />
          </FormColControl>
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('common:buttons.Cancel')}
        </Button>
        <Button onClick={submit} color="primary">
          {t('common:buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeviceForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: DeviceType,
}

export default DeviceForm
