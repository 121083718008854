export const columns = (t) => [
  {
    id: 'id',
    label: t('vehicles:fields.id'),
    numeric: true,
    width: '40px',
  },
  {
    id: 'createdBy',
    label: t('vehicles:fields.createdBy'),
  },
  {
    id: 'createdOn',
    label: t('vehicles:fields.createdOn'),
    datetime: true,
  },
  {
    id: 'updatedBy',
    label: t('vehicles:fields.updatedBy'),
  },
  {
    id: 'updatedOn',
    label: t('vehicles:fields.updatedOn'),
    datetime: true,
  },
  {
    id: 'description',
    label: t('vehicles:fields.description'),
  },
  {
    id: 'name',
    label: t('vehicles:fields.name'),
  },
  {
    id: 'notes',
    label: t('vehicles:fields.notes'),
  },
  {
    id: 'segment.code',
    label: t('vehicles:fields.segment.code'),
  },
  {
    id: 'segment.description',
    label: t('vehicles:fields.segment.description'),
  },
  {
    id: 'ownerId',
    label: t('vehicles:fields.ownerId'),
  },
  {
    id: 'owner.email',
    label: t('vehicles:fields.owner.email'),
  },
  {
    id: 'owner.name',
    label: t('vehicles:fields.owner.name'),
  },
  {
    id: 'owner.surname',
    label: t('vehicles:fields.owner.surname'),
  },
  {
    id: 'vehicles',
    label: t('vehicles:fields.vehicles'),
  },
]
