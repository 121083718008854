import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { selectVehicles } from '../Redux/Vehicles'
import { selectVehiclesFleets, request as vehiclesFleetsRequest } from '../Redux/VehiclesFleets'
import ConfirmationDialog from '../../Common/Components/ConfirmationDialog'
import TextField from '../../Common/Components/TextField'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ChangeListTable from '../../Common/Components/ChangeListTable'
import VehiclesFleetForm from '../Forms/VehiclesFleetForm'
import Loader from '../../Common/Components/Loader'
import { columns } from '../Models/VehiclesFleet'
import styled from 'styled-components'
import { setStateFromEvent } from '../../Common/Utils/Events'
import api from '../../Core/Services/Api'
import { request } from '../../Common/Utils/Api'
import * as R from 'ramda'
import { styled as muistyled } from '@mui/system'

const Wrapper = styled.div`
  padding-bottom: 70px;
`
const FilterForm = styled.div`
  padding: 1rem;
`

const AddFab = muistyled(Fab)`
    position: fixed;
    bottom: 20px;
    left: 340px;
`

const VehiclesFleetsList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const vehicles = useSelector(selectVehicles).data
  const vehiclesFleets = useSelector(selectVehiclesFleets).data
  const isLoading = useSelector(selectVehiclesFleets).fetching
  const [filterValue, setFilterValue] = React.useState('')

  const [form, setForm] = React.useState({ isOpen: false, vehiclesFleet: null })
  const [deleting, setDeleting] = React.useState({ isOpen: false, vehiclesFleet: null })

  // selected records
  const [selected, setSelected] = React.useState([])

  const handleSaveVehiclesFleet = async (fleet) => {
    const id = R.prop('id')(form.vehiclesFleet)
    const res = await request(
      api.vehiclesFleets.createOrUpdate,
      [fleet, id],
      t(`vehicles:success.VehiclesFleet${id ? 'Updated' : 'Created'}`, {
        fleet: fleet.name,
      }),
      `vehicles:errors.${id ? 'UpdateVehiclesFleetError' : 'CreateVehiclesFleetError'}`,
    )
    if (res.isSuccessful) {
      dispatch(vehiclesFleetsRequest())
      setForm({ isOpen: false, vehiclesFleet: null })
    }
    return res
  }

  const handleEdit = (vehiclesFleet) => {
    setForm({ isOpen: true, vehiclesFleet })
  }

  const handleDelete = (vehiclesFleet) => {
    setDeleting({ isOpen: true, vehiclesFleet })
  }

  const handleDeleteVehiclesFleet = async () => {
    const id = R.prop('id')(deleting.vehiclesFleet)
    const { isSuccessful } = await request(
      api.vehiclesFleets.delete,
      [id],
      t('vehicles:success.VehiclesFleetDeleted', {
        fleet: deleting.vehiclesFleet.description,
      }),
      'vehicles:errors.DeleteVehiclesFleetError',
    )
    if (isSuccessful) {
      dispatch(vehiclesFleetsRequest())
      setDeleting({ isOpen: false, vehiclesFleet: null })
    }
  }

  const actions = [
    { id: 'EDIT', label: t('common:generic.Edit'), cb: handleEdit, icon: <EditIcon /> },
    { id: 'DELETE', label: t('common:generic.Delete'), cb: handleDelete, icon: <DeleteIcon /> },
  ]

  return (
    <Wrapper>
      <FilterForm>
        <TextField
          label={t('common:generic.Filter')}
          style={{ width: '100%' }}
          value={filterValue}
          onChange={setStateFromEvent(setFilterValue)}
        />
      </FilterForm>
      {isLoading && <Loader minHeight="200px" />}
      {!isLoading && (
        <ChangeListTable
          noSettings
          name="vehiclesFleets"
          data={vehiclesFleets.filter(
            (v) => !filterValue || (v.description && v.description.toLowerCase().includes(filterValue)),
          )}
          selectable
          selected={selected}
          columns={columns(t)}
          sortField="name"
          sortDirection="asc"
          listDisplay={['name']}
          onSelect={setSelected}
          actions={actions}
        />
      )}
      <AddFab
        color="primary"
        aria-label={t('common:generic.add')}
        onClick={() => setForm({ isOpen: true, vehiclesFleet: null })}
      >
        <AddIcon style={{ cursor: 'pointer' }} />
      </AddFab>
      {form.isOpen && (
        <VehiclesFleetForm
          onClose={() => setForm({ isOpen: false, vehiclesFleet: null })}
          vehicles={vehicles}
          vehiclesFleet={form.vehiclesFleet}
          onSave={handleSaveVehiclesFleet}
        />
      )}
      {deleting.isOpen && (
        <ConfirmationDialog
          open
          title={t('vehicles:ui.DeleteFleetConfirmationTitle')}
          text={t('vehicles:ui.DeleteFleetConfirmationText', { fleet: deleting.vehiclesFleet.name })}
          onCancel={() => setDeleting({ isOpen: false, vehiclesFleet: null })}
          onOk={handleDeleteVehiclesFleet}
        />
      )}
    </Wrapper>
  )
}

VehiclesFleetsList.propTypes = {}

export default VehiclesFleetsList
