import React from 'react'
import AdminUsersView from './Views/AdminUsersView'

const AuthApp = () => {
  return (
    <AdminUsersView />
  )
}

export default AuthApp
