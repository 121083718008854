import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../Auth/Redux/Auth'
import { selectUsersData } from '../../../Auth/Redux/Users'
import ChangeListTable from '../../../Common/Components/ChangeListTable'
import { columns as deviceColumns } from '../../Models/Device'
import Loader from '../../../Common/Components/Loader'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SegmentDropdown from '../../../Common/Components/SegmentDropdown'
import UserField from '../../../Auth/Components/UserField'
import Alert from '@mui/material/Alert'
import { setStateFromEvent } from '../../../Common/Utils/Events'
import Button from '@mui/material/Button'
import TextField from '../../../Common/Components/TextField'
import { DeviceType } from '../../Types/Device'
import { DevicesFleetType } from '../../Types/DevicesFleet'
import styled from 'styled-components'
import * as R from 'ramda'
import logger from '../../../Common/Utils/Logger'
import api from '../../../Core/Services/Api'
import { request, setValidationErrors } from '../../../Common/Utils/Api'
import { validate } from './Validation'
import { FormRow, FormColControl } from '../../../Common/Components/Forms'
import { propOrDefault } from '../../../Common/Utils/Objects'

const FilterForm = styled.div`
  margin-bottom: 1rem;
`
const DevicesFleetForm = ({ onClose, onSave, devicesFleet, devices }) => {
  const { t } = useTranslation()
  const authData = useSelector(selectAuth)
  const users = useSelector(selectUsersData)
  const [isLoadingDevices, setIsLoadingDevices] = React.useState(false)

  const [filterValue, setFilterValue] = React.useState('')

  React.useEffect(async () => {
    if (devicesFleet) {
      setIsLoadingDevices(true)
      const { response, isSuccessful } = await request(
        api.devicesFleets.get,
        [devicesFleet.id],
        null,
        'devices.errors.FetchDevicesFleetError',
      )
      setIsLoadingDevices(false)
      if (isSuccessful) {
        setSelected(response.data.devices)
      }
    }
  }, [devicesFleet])

  const [selected, setSelected] = React.useState([])

  const authUserId = R.ifElse(
    R.isNil,
    R.always(null),
    R.prop('id'),
  )(R.find(R.propEq('email', authData.username), users)) // @TODO check if username is always email

  const [fields, setFields] = React.useState({
    name: propOrDefault(devicesFleet, 'name'),
    description: propOrDefault(devicesFleet, 'description'),
    segmentId: propOrDefault(devicesFleet, 'segmentId'),
    ownerId: propOrDefault(devicesFleet, 'ownerId', authUserId),
    notes: propOrDefault(devicesFleet, 'notes'),
  })
  const [errors, setErrors] = React.useState({})

  const setField = (field) => (value) => {
    setFields({ ...fields, [field]: value })
  }

  const submit = async () => {
    logger.debug('Devices fleet form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('Devices fleet form submission, validation passed, saving')
      const { response, isSuccessful } = await onSave({ ...fields, devices: selected.map((v) => v.serialNumber) })
      if (!isSuccessful) {
        setValidationErrors(response, setErrors)
      }
    } else {
      logger.debug('Devices fleet form submission, validation failed')
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t(`devices:ui.${devicesFleet ? 'Edit' : 'Add'}DevicesFleet`)}</DialogTitle>
      <DialogContent>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('devices:fields.name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              required
              label={t('devices:fields.description')}
              value={fields.description}
              onChange={setStateFromEvent(setField('description'))}
              error={!!errors.description}
              helperText={errors.description}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <UserField
              required
              label={t('devices:fields.owner.__field')}
              value={fields.ownerId}
              onChange={setField('ownerId')}
              error={!!errors.ownerId}
              helperText={errors.ownerId}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              label={t('devices:fields.notes')}
              value={fields.notes}
              onChange={setStateFromEvent(setField('notes'))}
              error={!!errors.notes}
              helperText={errors.notes}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <SegmentDropdown
              required
              value={fields.segmentId}
              onChange={setStateFromEvent(setField('segmentId'))}
              error={!!errors.segmentId}
              helperText={errors.segmentId}
            />
          </FormColControl>
          <FormColControl />
        </FormRow>
        {isLoadingDevices && <Loader />}
        {!isLoadingDevices && !fields.segmentId && (
          <Alert severity="warning">{t('devices:ui.SelectSegmentBeforeDevices')}</Alert>
        )}
        {!isLoadingDevices && fields.segmentId && (
          <>
            <Alert severity="info" style={{ marginBottom: '1rem' }}>
              {t('devices:ui.SelectFleetDevices')}
            </Alert>
            <FilterForm>
              <TextField
                label={t('common:generic.FilterBy', { filter: 'serial number' })}
                style={{ width: '100%' }}
                value={filterValue}
                onChange={setStateFromEvent(setFilterValue)}
              />
            </FilterForm>
            <ChangeListTable
              name="devicesFleetForm"
              data={devices.filter(
                (v) =>
                  v.segmentId === fields.segmentId &&
                  (!filterValue || v.serialNumber.toLowerCase().includes(filterValue.toLowerCase())),
              )}
              selectable
              selected={selected}
              columns={deviceColumns(t)}
              sortField="serialNumber"
              sortDirection="asc"
              listDisplay={['serialNumber', 'description', 'deviceStatus.description', 'activated']}
              onSelect={setSelected}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('common:buttons.Cancel')}
        </Button>
        <Button onClick={submit} color="primary">
          {t('common:buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DevicesFleetForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  devicesFleet: DevicesFleetType,
  devices: PropTypes.arrayOf(DeviceType),
}

export default DevicesFleetForm
