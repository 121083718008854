import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as rolesRequest, selectRolesData } from '../Redux/Roles'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const RoleDropdown = ({ value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const role = useSelector(selectRolesData)

  return (
    <Dropdown
      label={t('auth:fields.role.__field')}
      required={required}
      value={value}
      onChange={onChange}
      options={role.length ? role.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={rolesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

RoleDropdown.defaultProps = {
  required: false,
}

RoleDropdown.propTypes = FieldType

export default RoleDropdown
