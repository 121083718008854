// @TODO
// consider adding a "refresh" property, triggered by socket which can be
// used to decide if performing a news request when entering a route
// otherwise we can always refresh on socket message, or ask the user
export const initialApiState = (defaultData) => ({
  fetching: false,
  fetched: false,
  error: false,
  errorMessage: null,
  errorCode: null,
  data: defaultData,
})

export const requestReducer = (state) => ({ ...state, fetching: true })
export const successReducer = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  error: false,
  errorMessage: null,
  data: action.payload,
})
export const failureReducer = (defaultData) => (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  error: true,
  errorMessage: action.payload.message,
  errorCode: action.payload.code,
  data: defaultData,
})

export const apiReducers = (defaultData) => ({
  request: requestReducer,
  success: successReducer,
  failure: failureReducer(defaultData),
})

export const apiSlice = (name, defaultData) => ({
  name,
  initialState: initialApiState(defaultData),
  reducers: apiReducers(defaultData),
})
