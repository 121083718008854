import PropTypes from 'prop-types'
import { VehicleType } from './Vehicle'
import { UserType } from '../../Auth/Types/User'

export const VehiclesFleetType = PropTypes.shape({
  id: PropTypes.number,
  createdBy: PropTypes.string,
  createdOn: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  segmentId: PropTypes.number,
  segment: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  notes: PropTypes.string,
  ownerId: PropTypes.number,
  owner: UserType,
  vehicles: PropTypes.arrayOf(VehicleType),
})
