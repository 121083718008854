import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const platformsSlice = createSlice(apiSlice('platforms', []))

export const { request, success, failure } = platformsSlice.actions

export default platformsSlice.reducer

export const selectPlatforms = (state) => state.platforms
export const selectPlatformsData = (state) => state.platforms.data

// side effects
export const fetchPlatformsEpic = (action$) =>
  action$.pipe(
    ofType('platforms/request'),
    mergeMap(() =>
      from(api.platforms.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchPlatformsError',
        ),
      ),
    ),
  )
