import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #f3f3f3;
  box-shadow: 0 0 10px #999;
  position: relative;
`

const Sidebar = ({ children, width }) => {
  return (
    <Wrapper width={width}>
      {children}
    </Wrapper>
  )
}

Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  width: PropTypes.string,
}

export default Sidebar
