import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormControl } from '../../Common/Components/Forms'
import TextField from '../../Common/Components/TextField'
// import SegmentDropdown from '../../Common/Components/SegmentDropdown'
import ResourceTypeDropdown from './ResourceTypeDropdown'
import DatePicker from '../../Common/Components/DatePicker'
import Checkbox from '../../Common/Components/Checkbox'
import Slider from '@mui/material/Slider'
// import Dropdown from '../../Common/Components/Dropdown'
import Loader from '../../Common/Components/Loader'
import { humanizeSize } from '../../Common/Utils/Files'
import { setStateFromEvent } from '../../Common/Utils/Events'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 1rem;
  width: 220px;
`

const Summary = styled.div`
  margin-top: 1rem;
  font-weight: bold;
`

const ResourcesSidebar = ({ fields, onFieldChange, totalFiltered, total, loading, tab }) => {
  const { t } = useTranslation()

  const setField = (field) => (value) => {
    onFieldChange(field, value)
  }

  return (
    <Wrapper>
      <FormControl compact>
        <TextField
          label={t('resources:fields.fileName')}
          value={fields.fileName}
          onChange={setStateFromEvent(setField('fileName'))}
        />
      </FormControl>
      <FormControl compact>
        <ResourceTypeDropdown value={fields.resourceTypeId} onChange={setStateFromEvent(setField('resourceTypeId'))} />
      </FormControl>
      {tab === 0 && (
        <>
          <FormControl compact>
            <DatePicker
              label={t('resources:fields.updatedOn')}
              value={fields.updatedOn}
              onChange={setField('updatedOn')}
            />
          </FormControl>
          <FormControl compact>
            <TextField
              label={t('resources:fields.updatedBy')}
              value={fields.updatedBy}
              onChange={setStateFromEvent(setField('updatedBy'))}
            />
          </FormControl>
          <FormControl compact>
            <TextField
              label={t('resources:fields.tag')}
              value={fields.tag}
              onChange={setStateFromEvent(setField('tag'))}
            />
          </FormControl>
          {/* <FormControl compact>
            <SegmentDropdown value={fields.segmentId} onChange={setStateFromEvent(setField('segmentId'))} />
          </FormControl> */}
          <FormControl compact style={{ marginTop: '1.5rem' }}>
            <label style={{ color: 'rgba(0, 0, 0, 0.54)', letterSpacing: '0.00938em' }}>
              {t('resources:fields.fileSize')} &gt;= {humanizeSize(fields.fileSize)}
            </label>
            <Slider
              value={fields.fileSize}
              onChange={(_, val) => setField('fileSize')(val)}
              min={0}
              max={5000000}
              step={10000}
            />
          </FormControl>
          <FormControl compact>
            <Checkbox
              label={t('resources:ui.ShowArchived')}
              checked={fields.archived}
              onChange={(event) => setField('archived')(event.target.checked)}
            />
          </FormControl>
        </>
      )}
      {loading && <Loader size={20} />}
      {!loading && (
        <Summary>
          List items: {totalFiltered}/{total}
        </Summary>
      )}
    </Wrapper>
  )
}

ResourcesSidebar.propTypes = {
  fields: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  totalFiltered: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  tab: PropTypes.number,
}

export default ResourcesSidebar
