import React from 'react'
// import PropTypes from 'prop-types'
import { BreadcrumbsType } from '../Types/Breadcrumbs'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import LogoImage from '../../Assets/Images/logo-h.svg'
import UserIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import { logout } from '../../Auth/Services'
import styled from 'styled-components'
import history from '../history'
import config from '../Config'
import * as R from 'ramda'

const Container = styled.div`
  align-items: center;
  background: #f3f3f3;
  box-shadow: 0 0 1rem #999;
  display: flex;
  padding: 0.5rem;
  z-index: 10;
`
const Logo = styled.img`
  height: 40px;
`

const Separator = styled.div`
  background: #9a9a9a;
  height: 40px;
  margin: 0 1rem;
  width: 1px;
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`

const BreadcrumbsText = styled(Typography)`
  font-size: 1.2rem !important;
  font-weight: 300 !important;
`

const BreadcrumbsLink = styled(Link)`
  cursor: pointer;
  font-size: 1.2rem !important;
  font-weight: 300 !important;
`

const Navbar = ({ breadcrumbs }) => {
  const { instance: msalInstance } = useMsal()
  const { t } = useTranslation()
  const items = [
    { label: t('core:navigation.Home'), handleClick: () => history.push(config.urls.home) },
    ...breadcrumbs,
  ]
  return (
    <Container>
      <Logo src={LogoImage} />
      <Separator />
      <Breadcrumbs aria-label="breadcrumb">
        {items.map((item, index) => {
          return R.ifElse(
            R.identity,
            R.always(
              <BreadcrumbsText key={`item-${index}`} color="textPrimary">
                {item.label}
              </BreadcrumbsText>,
            ),
            R.always(
              <BreadcrumbsLink key={`item-${index}`} style={{ cursor: 'pointer' }} onClick={item.handleClick}>
                {item.label}
              </BreadcrumbsLink>,
            ),
          )(index === items.length - 1)
        })}
      </Breadcrumbs>
      <Actions>
        <UserIcon />
        <Separator />
        <LogoutIcon
          onClick={() => logout(msalInstance, () => window.location.reload())}
          style={{ cursor: 'pointer' }}
        />
      </Actions>
    </Container>
  )
}

Navbar.defaultProps = {
  breadcrumbs: [],
}

Navbar.propTypes = {
  breadcrumbs: BreadcrumbsType,
}

export default Navbar
