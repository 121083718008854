import styled from 'styled-components'
import { FlexRow } from './Flex'

export const FormRow = styled(FlexRow)`
  justify-content: space-between;
`

export const FormControl = styled.div`
  padding-bottom: ${props => props.compact ? '1rem' : '2rem'};

  > * {
    width: 100%;
  }
`

export const FormColControl = styled.div`
  max-width: 49%;
  min-width: 49%;
  padding-bottom: 2rem;

  &:first-child {
    padding-right: 1rem;
  }

  &:last-child {
    padding-left: 1rem;
  }

  > * {
    width: ${props => props.noFullChild ? 'auto' : '100%'};
  }
`
