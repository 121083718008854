import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 640 512" style={style}><path fill={color} d="M220,164c0-55.2,44.8-100,100-100s100,44.8,100,100s-44.8,100-100,100S220,219.2,220,164z M48,208 c0-44.2,35.8-80,80-80s80,35.8,80,80s-35.8,80-80,80S48,252.2,48,208z M432,208c0-44.2,35.8-80,80-80s80,35.8,80,80s-35.8,80-80,80 S432,252.2,432,208z M410,284c38.7,0,70,31.3,70,70v70c0,13.3-10.7,24-24,24H184c-13.3,0-24-10.7-24-24v-70c0-38.7,31.3-70,70-70	h17.8c44.4,26.7,99.9,26.7,144.3,0H410 M128,354c0-12,2.1-23.5,5.9-34.2C112,321,89.8,315.7,70.3,304H56c-30.9,0-56,25.1-56,56v32	c0,13.3,10.7,24,24,24h104V354z M584,304h-14.3c-19.5,11.7-41.7,17-63.6,15.8c3.9,11,5.9,22.5,5.9,34.2v62h104c13.3,0,24-10.7,24-24	v-32C640,329.1,614.9,304,584,304z"/></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
