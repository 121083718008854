import ResourcesReducer, { fetchResourcesEpic } from './Resources'
import ResourcesTypesReducer, { fetchResourcesTypesEpic } from './ResourcesTypes'
import ResourcesJobsReducer, { fetchResourcesJobsEpic } from './ResourcesJobs'

export const epics = [
  fetchResourcesEpic,
  fetchResourcesTypesEpic,
  fetchResourcesJobsEpic,
]

export default {
  resources: ResourcesReducer,
  resourcesTypes: ResourcesTypesReducer,
  resourcesJobs: ResourcesJobsReducer,
}
