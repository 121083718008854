import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { request as resourcesRequest } from '../Redux/Resources'
import { request as resourcesJobsRequest } from '../Redux/ResourcesJobs'
import { selectResourcesTypesData } from '../Redux/ResourcesTypes'
import { useTranslation } from 'react-i18next'
import { ResourceType } from '../Types/Resource'
import { columns } from '../Models/Resource'
import ChangeListTable from '../../Common/Components/ChangeListTable'
import ResourceForm from '../Forms/ResourceForm'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
// import EditIcon from '@mui/icons-material/Edit'
import Loader from '../../Common/Components/Loader'
import { request } from '../../Common/Utils/Api'
import api from '../../Core/Services/Api'
import { useConfirm } from '../../Common/Utils/Hooks'
import styled from 'styled-components'
// import * as R from 'ramda'
import { styled as muistyled } from '@mui/system'

const Container = styled.div`
  width: 100%;
`
const AddFab = muistyled(Fab)`
    position: fixed;
    bottom: 2rem;
    right: 2rem;
`

const ResourcesList = ({ resources, loading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const resourcesTypes = useSelector(selectResourcesTypesData)

  const [selected, setSelected] = React.useState([])

  const [uploadProgressEvent, setUploadProgressEvent] = React.useState(null)
  const [form, setForm] = React.useState({ isOpen: false, resource: null })
  // const handleEdit = (resource) => setForm({ isOpen: true, resource })

  const handleSaveResource = async (resource) => {
    const extension = resource.file[0].name.split('.').pop()
    const fileTypeId = resourcesTypes.filter((rt) => rt.extensions.split(',').indexOf(extension) !== -1)[0].id
    const formData = new FormData()
    formData.append('file', resource.file[0])
    if (resource.tags) {
      formData.append('tags', resource.tag)
    }
    formData.append('ecuDataFileTypeId', fileTypeId.toString())

    const res = await request(
      api.resources.create,
      [formData, setUploadProgressEvent],
      t('resources:success.ResourceJobCreated', {
        resource: resource.file[0].name,
      }),
      'resources:errors.CreateResourceError',
    )
    if (res.isSuccessful) {
      dispatch(resourcesRequest())
      dispatch(resourcesJobsRequest())
      setForm({ isOpen: false, resource: null })
    }
    return res
  }

  const handleDeleteResources = async () => {
    closeDeleteConfirm()
    const promises = selected.map(async (resource) => {
      const response = await request(
        api.resources.delete,
        [resource.id],
        t('resources:success.ResourceDeleted', {
          resource: resource.fileName,
        }),
        'resources:errors.DeleteResourceError',
      )
      return response
    })
    await Promise.all(promises)
    setSelected([])
    setUploadProgressEvent(null)

    dispatch(resourcesRequest())
  }

  const handleArchiveResources = async () => {
    closeArchiveConfirm()
    const promises = selected.map(async (resource) => {
      const response = await request(
        api.resources.patch,
        [resource.id, { archived: true }],
        t('resources:success.ResourceArchived', {
          resource: resource.fileName,
        }),
        'resources:errors.ArchiveResourceError',
      )
      return response
    })
    await Promise.all(promises)
    setSelected([])
    dispatch(resourcesRequest())
  }

  const handleUnarchiveResources = async () => {
    closeUnarchiveConfirm()
    const promises = selected.map(async (resource) => {
      const response = await request(
        api.resources.patch,
        [resource.id, { archived: false }],
        t('resources:success.ResourceUnarchived', {
          resource: resource.fileName,
        }),
        'resources:errors.UnarchiveResourceError',
      )
      return response
    })
    await Promise.all(promises)
    setSelected([])
    dispatch(resourcesRequest())
  }

  const [openDeleteConfirm, closeDeleteConfirm, DeleteConfirm] = useConfirm(handleDeleteResources)
  const [openArchiveConfirm, closeArchiveConfirm, ArchiveConfirm] = useConfirm(handleArchiveResources)
  const [openUnarchiveConfirm, closeUnarchiveConfirm, UnarchiveConfirm] = useConfirm(handleUnarchiveResources)

  // bulk
  const renderSelectedResourcesList = (text) => (
    <>
      {text}
      <ul>
        {selected.map((r) => (
          <li key={`id-${r.id}`}>{r.fileName}</li>
        ))}
      </ul>
    </>
  )
  const bulkActions = [
    {
      id: 'DELETE',
      label: t('resources:ui.DeleteSelectedResources'),
      cb: () =>
        openDeleteConfirm(
          null, // payload
          t('resources:ui.DeleteResourcesConfirmationTitle'),
          renderSelectedResourcesList(t('resources:ui.DeleteResourcesConfirmationText')),
        ),
    },
    {
      id: 'ARCHIVE',
      label: t('resources:ui.ArchiveSelectedResources'),
      cb: () =>
        openArchiveConfirm(
          null, // payload
          t('resources:ui.ArchiveResourcesConfirmationTitle'),
          renderSelectedResourcesList(t('resources:ui.ArchiveResourcesConfirmationText')),
        ),
    },
    {
      id: 'UNARCHIVE',
      label: t('resources:ui.UnarchiveSelectedResources'),
      cb: () =>
        openUnarchiveConfirm(
          null, // payload
          t('resources:ui.UnarchiveResourcesConfirmationTitle'),
          renderSelectedResourcesList(t('resources:ui.UnarchiveResourcesConfirmationText')),
        ),
    },
  ]

  return (
    <Container>
      {loading && <Loader minHeight="200px" />}
      {!loading && (
        <ChangeListTable
          name="resources"
          selectable
          data={resources}
          columns={columns(t)}
          sortField="fileName"
          sortDirection="asc"
          listDisplay={[
            'fileName',
            'ecuDataFileType.code',
            'version',
            'fileSize',
            'updatedBy',
            'updatedOn',
            'archived',
          ]}
          bulkActions={bulkActions}
          selected={selected}
          onSelect={setSelected}
        />
      )}

      {form.isOpen && (
        <ResourceForm
          onClose={() => setForm({ isOpen: false, resource: null })}
          resource={form.resource}
          onSave={handleSaveResource}
          uploadProgressEvent={uploadProgressEvent}
        />
      )}
      {DeleteConfirm}
      {ArchiveConfirm}
      {UnarchiveConfirm}
      <AddFab
        color="primary"
        aria-label={t('common:generic.add')}
        onClick={() => setForm({ isOpen: true, resource: null })}
      >
        <AddIcon style={{ cursor: 'pointer' }} />
      </AddFab>
    </Container>
  )
}

ResourcesList.propTypes = {
  resources: PropTypes.arrayOf(ResourceType),
  loading: PropTypes.bool,
}

export default ResourcesList
