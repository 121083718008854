import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const domainsSlice = createSlice(apiSlice('domains', []))

export const { request, success, failure } = domainsSlice.actions

export default domainsSlice.reducer

export const selectDomains = (state) => state.domains
export const selectDomainsData = (state) => state.domains.data

// side effects
export const fetchDomainsEpic = (action$) =>
  action$.pipe(
    ofType('domains/request'),
    mergeMap(() =>
      from(api.domains.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchDomainsError',
        ),
      ),
    ),
  )
