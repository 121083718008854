import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { request as productLinesRequest, selectProductLinesData } from '../../Core/Redux/ProductLines'
import PropTypes from 'prop-types'
import { FieldType } from '../Types/Field'
import Dropdown from './Dropdown'
import * as R from 'ramda'

const PlatformDropdown = ({ label, value, onChange, required, productLineId, error, helperText }) => {
  const { t } = useTranslation()
  const productLines = useSelector(selectProductLinesData)

  const options = R.ifElse(
    R.isNil,
    R.always([]),
    R.always(
      R.ifElse(
        R.isNil,
        R.always([]),
        R.pipe(
          R.prop('platforms'),
          R.map((s) => ({ value: s.id, label: s.description })),
        ),
      )(R.find(R.propEq('id', productLineId))(productLines)),
    ),
  )(productLineId)

  return (
    <Dropdown
      label={label || t('common:fields.platform')}
      required={required}
      value={value}
      onChange={onChange}
      options={options}
      requestAction={productLinesRequest}
      error={error}
      helperText={helperText || (productLineId ? undefined : t('common:ui.selectProductLineFirst'))}
    />
  )
}

PlatformDropdown.defaultProps = {
  required: false,
}

PlatformDropdown.propTypes = {
  ...FieldType,
  productLineId: PropTypes.number,
}

export default PlatformDropdown
