import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../Auth/Redux/Auth'
import { useRouteMatch } from 'react-router-dom'
import HomeIcon from '../../Common/Icons/Home'
import VehicleIcon from '../../Common/Icons/Tractor'
import DeviceIcon from '../../Common/Icons/Device'
import ConfiguratorIcon from '../../Common/Icons/Configurator'
import ResourceIcon from '../../Common/Icons/Resource'
import UsersIcon from '../../Common/Icons/Users'
import ReportIcon from '../../Common/Icons/Report'
import SettingsIcon from '../../Common/Icons/Settings'
import UpdateBundlesIcon from '../../Common/Icons/Update'
import { VehiclesPermissions, DevicesPermissions, UsersPermissions, ResourcesPermissions } from '../../Auth/Permissions'
import config from '../Config'
import history from '../history'
import styled from 'styled-components'

const Sidebar = styled.nav`
  background: #9a9a9a;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
`

const Item = styled.div`
  background: ${(props) => (props.active ? '#29434e' : 'transparent')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem 0.5rem;

  svg {
    height: 40px;
  }
`

const Separator = styled.div`
  background: #fff;
  height: 2px;
  margin: 0 0 2rem;
`

const Menu = ({ style }) => {
  const { path } = useRouteMatch()
  const user = useSelector(selectAuth)

  const goTo = (path) => () => history.push(path)

  return (
    <Sidebar style={ style }>
      <Item active={path === config.urls.home} onClick={goTo(config.urls.home)}>
        <HomeIcon />
      </Item>
      <Separator />
      {VehiclesPermissions.view(user) && (
        <Item active={path.includes(`${config.urls.vehicles}`)} onClick={goTo(config.urls.vehicles)}>
          <VehicleIcon />
        </Item>
      )}
      {DevicesPermissions.view(user) && (
        <Item active={path.includes(`${config.urls.devices}`)} onClick={goTo(config.urls.devices)}>
          <DeviceIcon />
        </Item>
      )}

      <Item>
        <ConfiguratorIcon />
      </Item>
      {ResourcesPermissions.view(user) && (
        <Item active={path.includes(`${config.urls.resources}`)} onClick={goTo(config.urls.resources)}>
          <ResourceIcon />
        </Item>
      )}
      {UsersPermissions.view(user) && (
        <Item active={path.includes(`${config.urls.users}`)} onClick={goTo(config.urls.users)}>
          <UsersIcon />
        </Item>
      )}
      <Item>
        <ReportIcon />
      </Item>
      <Item>
        <SettingsIcon />
      </Item>
      <Item>
        <UpdateBundlesIcon />
      </Item>
    </Sidebar>
  )
}

Menu.propTypes = {
  style: PropTypes.object,
}

export default Menu
