import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormControl } from '../../Common/Components/Forms'
import TextField from '../../Common/Components/TextField'
import SegmentDropdown from '../../Common/Components/SegmentDropdown'
import Dropdown from '../../Common/Components/Dropdown'
import Loader from '../../Common/Components/Loader'
import { setStateFromEvent } from '../../Common/Utils/Events'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 1rem;
  width: 280px;
`

const Summary = styled.div`
  margin-top: 1rem;
  font-weight: bold;
`

const UsersSidebar = ({ fields, onFieldChange, totalFiltered, total, loading }) => {
  const { t } = useTranslation()

  const setField = (field) => (value) => {
    onFieldChange(field, value)
  }

  return (
    <Wrapper>
      <FormControl compact>
        <TextField
          label={t('auth:fields.email')}
          value={fields.email}
          onChange={setStateFromEvent(setField('email'))}
        />
      </FormControl>
      <FormControl compact>
        <TextField
          label={t('auth:fields.surname')}
          value={fields.surname}
          onChange={setStateFromEvent(setField('surname'))}
        />
      </FormControl>
      <FormControl compact>
        <SegmentDropdown value={fields.segmentId} onChange={setStateFromEvent(setField('segmentId'))} />
      </FormControl>
      <FormControl compact>
        <Dropdown
          label={t('auth:fields.isEnabled')}
          value={fields.isEnabled}
          onChange={setStateFromEvent(setField('isEnabled'))}
          options={[
            { value: true, label: t('common:generic.Yes') },
            { value: false, label: t('common:generic.No') },
          ]}
        />
      </FormControl>
      {loading && <Loader size={20} />}
      {!loading && <Summary>List items: {totalFiltered}/{total}</Summary>}
    </Wrapper>
  )
}

UsersSidebar.propTypes = {
  fields: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  totalFiltered: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool,
}

export default UsersSidebar
