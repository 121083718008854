import i18next from 'i18next'
import * as R from 'ramda'

const required = ['file']

export const validate = (fields, setErrors) => {
  const err = {}
  const nothing = () => {}

  // check required
  const setRequired = (field) => {
    err[field] = i18next.t('common:validation.requiredField')
  }
  required.forEach(R.ifElse(R.flip(R.prop)(fields), nothing, setRequired))

  // check file
  R.ifElse(R.propEq('length', 0), () => setRequired('file'), nothing)(fields.file)

  setErrors(err)
  return R.isEmpty(err)
}
