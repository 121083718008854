import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const segmentsSlice = createSlice(apiSlice('segments', []))

export const { request, success, failure } = segmentsSlice.actions

export default segmentsSlice.reducer

export const selectSegments = (state) => state.segments
export const selectSegmentsData = (state) => state.segments.data

// side effects
export const fetchSegmentsEpic = (action$) =>
  action$.pipe(
    ofType('segments/request'),
    mergeMap(() =>
      from(api.segments.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchSegmentsError',
        ),
      ),
    ),
  )
