import PropTypes from 'prop-types'

export const UserType = PropTypes.shape({
  accountName: PropTypes.string,
  createdBy: PropTypes.string,
  createdOn: PropTypes.string,
  domainId: PropTypes.number,
  email: PropTypes.string,
  id: PropTypes.number,
  isEnabled: PropTypes.bool,
  name: PropTypes.string,
  roleId: PropTypes.number,
  segmentId: PropTypes.number,
  surname: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string,
})
