import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../Common/Components/Flex'
import VehiclesSidebar from '../Vehicles/Components/VehiclesSidebar'
import BaseLayout from '../Core/Layouts/BaseLayout'
import Router from './Router'
import config from '../Core/Config'

const VehiclesApp = () => {
  const { t } = useTranslation()
  const breadcrumbs = [{ label: t('core:navigation.Vehicles'), handleClick: () => history.push(config.urls.vehicles) }]

  return (
    <BaseLayout showMenu breadcrumbs={breadcrumbs} sidebar={<VehiclesSidebar />}>
      <FlexRow style={{ minHeight: '100%' }}>
        <Router />
      </FlexRow>
    </BaseLayout>
  )
}

export default VehiclesApp
