import logger from '../Common/Utils/Logger'
import { loginRequest, authTokenRequest } from './Config'
import { request, success, failure } from './Redux/Auth'
import { EventType } from '@azure/msal-browser'
import api from '../Core/Services/Api'
import history from '../Core/history'
import config from '../Core/Config'

let loginRedirectUrl = config.urls.home

export const authenticate = (msalInstance, dispatch, completeInit, prefetch) => {
  dispatch(request())
  // Get current logged in accounts
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    // ok, logged in
    msalInstance.setActiveAccount(accounts[0])
    logger.info('Account: ', accounts[0])
    dispatch(success(accounts[0]))
    // retrieve jwt
    getJwt(msalInstance, completeInit, prefetch)
  } else {
    // not authenticated, empty user
    dispatch(success({}))
    completeInit()
  }

  // add login success listener
  msalInstance.addEventCallback(
    (event) => {
      logger.debug('Msal event:', event)
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account
        msalInstance.setActiveAccount(account)
        dispatch(success(account))
        getJwt(msalInstance, completeInit, prefetch)
        history.push(loginRedirectUrl)
      }
    },
    (error) => {
      dispatch(failure(error))
      logger.error('Login error:', error)
    },
  )
}

export const login = (msalInstance, username = null, next = config.urls.home) => {
  loginRedirectUrl = next
  msalInstance
    .loginPopup(username ? { ...loginRequest, loginHint: username } : loginRequest)
    .catch((err) => {
      logger.warning('Login flow error:', err)
    })
}

export const logout = (msalInstance, completeInit = () => {}) => {
  msalInstance
    .logoutPopup()
    .then((_) => completeInit())
    .catch((err) => {
      logger.warning('Logout flow error:', err)
    })
}

export const getJwt = (msalInstance, completeInit, prefetch) => {
  msalInstance
    .acquireTokenSilent(authTokenRequest)
    .then((response) => {
      api.setAuthToken(response.accessToken)
      completeInit()
      prefetch()
    })
    .catch((_) => {
      msalInstance.acquireTokenPopup(authTokenRequest).then((response) => {
        api.setAuthToken(response.accessToken)
        completeInit()
        prefetch()
      })
    })
}
