import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const sitesSlice = createSlice(apiSlice('sites', []))

export const { request, success, failure } = sitesSlice.actions

export default sitesSlice.reducer

export const selectSites = (state) => state.sites
export const selectSitesData = (state) => state.sites.data

// side effects
export const fetchSitesEpic = (action$) =>
  action$.pipe(
    ofType('sites/request'),
    mergeMap(() =>
      from(api.sites.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchSitesError',
        ),
      ),
    ),
  )
