import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as domainsRequest, selectDomainsData } from '../../Core/Redux/Domains'
import { FieldType } from '../Types/Field'
import Dropdown from './Dropdown'

const DomainDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const domains = useSelector(selectDomainsData)

  return (
    <Dropdown
      label={label || t('common:fields.domain')}
      required={required}
      value={value}
      onChange={onChange}
      options={domains.length ? domains.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={domainsRequest}
      error={error}
      helperText={helperText}
    />
  )
}

DomainDropdown.defaultProps = {
  required: false,
}

DomainDropdown.propTypes = FieldType

export default DomainDropdown
