import React from 'react'
import { FieldType } from '../Types/Field'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiCheckbox from '@mui/material/Checkbox'

/**
 * A wrpper to make it easy to add functionality, i.e. clearable
 */
const Checkbox = ({ label, style, ...props }) => {
  // get rid of "I don't want null" fucking message from MUI
  return (
    <FormControlLabel
      control={<MuiCheckbox {...props} />}
      label={label}
      style={{ ...style, color: 'rgba(0, 0, 0, 0.54)', letterSpacing: '0.00938em' }}
    />
  )
}

Checkbox.propTypes = FieldType

export default Checkbox
