import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as engineTypesRequest, selectEngineTypesData } from '../Redux/EngineTypes'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const EngineTypeDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const engineTypes = useSelector(selectEngineTypesData)

  return (
    <Dropdown
      label={label || t('vehicles:fields.engineType.__field')}
      required={required}
      value={value}
      onChange={onChange}
      options={engineTypes.length ? engineTypes.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={engineTypesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

EngineTypeDropdown.defaultProps = {
  required: false,
}

EngineTypeDropdown.propTypes = FieldType

export default EngineTypeDropdown
