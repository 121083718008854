import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../Common/Components/Flex'
import DeviceSidebar from '../Devices/Components/DevicesSidebar'
import BaseLayout from '../Core/Layouts/BaseLayout'
import Router from './Router'
import config from '../Core/Config'

const DevicesApp = () => {
  const { t } = useTranslation()
  const breadcrumbs = [{ label: t('core:navigation.Devices'), handleClick: () => history.push(config.urls.devices) }]

  return (
    <BaseLayout showMenu breadcrumbs={breadcrumbs} sidebar={<DeviceSidebar />}>
      <FlexRow style={{ minHeight: '100%' }}>
        <Router />
      </FlexRow>
    </BaseLayout>
  )
}

export default DevicesApp
