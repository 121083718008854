export const columns = (t) => [
  {
    id: 'id',
    label: t('resources:fields.id'),
    numeric: true,
    width: '40px',
  },
  {
    id: 'startedOn',
    label: t('resources:fields.startedOn'),
    datetime: true,
  },
  {
    id: 'finishedOn',
    label: t('resources:fields.finishedOn'),
    datetime: true,
  },
  {
    id: 'loggedOn',
    label: t('resources:fields.loggedOn'),
    datetime: true,
  },
  {
    id: 'importProgress',
    label: t('resources:fields.importProgress'),
  },
  {
    id: 'hasError',
    label: t('resources:fields.hasError'),
    boolean: true,
  },
  {
    id: 'status',
    label: t('resources:fields.status'),
    abstract: true,
  },
  {
    id: 'errorDescription',
    label: t('resources:fields.errorDescription'),
  },
  {
    id: 'errorCode',
    label: t('resources:fields.errorCode'),
  },
  {
    id: 'ecuDataFile.fileName',
    label: t('resources:fields.ecuDataFile.fileName'),
  },
  {
    id: 'ecuDataFile.ecuDataFileType.code',
    label: t('resources:fields.ecuDataFileType.code'),
  },
  {
    id: 'ecuDataFile.importCompleted',
    label: t('resources:fields.ecuDataFile.importCompleted'),
    boolean: true,
  },
  {
    id: 'ecuDataFile.version',
    label: t('resources:fields.ecuDataFile.version'),
  },
]
