import i18next from 'i18next'
import * as R from 'ramda'

const required = ['name', 'ownerId']

export const validate = (fields, setErrors) => {
  const err = {}
  const nothing = () => {}
  const setRequired = (field) => {
    err[field] = i18next.t('common:validation.requiredField')
  }
  required.forEach(R.ifElse(R.flip(R.prop)(fields), nothing, setRequired))

  setErrors(err)
  return R.isEmpty(err)
}
