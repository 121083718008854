import React from 'react'
import { useSelector } from 'react-redux'
import { selectStartupIniting } from './Redux/Startup'
import { ToastContainer } from 'react-toastify'
import AppRouter from './Router'
import LoadingView from './Views/LoadingView'
import * as R from 'ramda'

import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const startupIniting = useSelector(selectStartupIniting)
  const withLoading = R.ifElse(R.not, () => <LoadingView />)

  return withLoading(
    R.always(
      <>
        <ToastContainer />
        <AppRouter />
      </>,
    ),
  )(!startupIniting)
}

App.propTypes = {}

export default App
