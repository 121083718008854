import React from 'react'
import { useSelector } from 'react-redux'
import { selectResourcesTypesData } from '../../Redux/ResourcesTypes'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
// import TextField from '../../../Common/Components/TextField'
import FileField from '../../../Common/Components/FileField'
import TagField from '../../../Common/Components/TagField'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import logger from '../../../Common/Utils/Logger'
import { ResourceType } from '../../Types/Resource'
import { propOrDefault } from '../../../Common/Utils/Objects'
import { FormControl } from '../../../Common/Components/Forms'
import { validate } from './Validation'
import { setValidationErrors } from '../../../Common/Utils/Api'

const ResourceForm = ({ onClose, onSave, resource, uploadProgressEvent }) => {
  const { t } = useTranslation()
  const resourcesTypes = useSelector(selectResourcesTypesData)

  const progress = uploadProgressEvent ? parseInt((uploadProgressEvent.loaded * 100) / uploadProgressEvent.total) : null

  const [fields, setFields] = React.useState({
    file: propOrDefault(resource, 'file', []),
    tag: propOrDefault(resource, 'tag', ''),
  })
  const setField = (field) => (value) => {
    setFields({ ...fields, [field]: value })
  }

  const [errors, setErrors] = React.useState({})

  const onDrop = (acceptedFiles) => {
    setField('file')(acceptedFiles)
  }

  const submit = async () => {
    logger.debug('Resource form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('Resource form submission, validation passed, saving')
      const { response, isSuccessful } = await onSave(fields)
      if (!isSuccessful) {
        setValidationErrors(response, setErrors)
      }
    } else {
      logger.debug('Resource form submission, validation failed')
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t('resources:ui.AddResource')}</DialogTitle>
      <DialogContent>
        <FileField
          onDrop={onDrop}
          files={fields.file}
          accept={resourcesTypes
            .reduce((acc, curr) => [...acc, curr.extensions.split(',')], [])
            .map((e) => `.${e}`)
            .join(',')}
          error={!!errors.file}
          helperText={errors.file}
        />
        {progress && (
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
            </Box>
          </Box>
        )}
        <FormControl style={{ marginTop: '2rem' }}>
          <TagField
            label={t('resources:fields.tag')}
            value={fields.tag ? fields.tag.split(',') : []}
            onChange={(tags) => setField('tag')(tags.map((t) => t.trim()).join(','))}
            inputFieldPosition="bottom"
            autocomplete
            error={!!errors.tag}
            helperText={errors.tag}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('common:buttons.Cancel')}
        </Button>
        <Button onClick={submit} color="primary">
          {t('common:buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ResourceForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  resource: ResourceType,
  uploadProgressEvent: PropTypes.shape({
    loaded: PropTypes.number,
    total: PropTypes.number,
  }),
}

export default ResourceForm
