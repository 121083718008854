import { map, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import i18next from 'i18next'
import { toast } from 'react-toastify'
import logger from './Logger'
import * as R from 'ramda'

export const manageEpicResponse = (success, failure, errorStringId) => {
  return [
    map((response) => {
      if (!response) {
        toast.error(i18next.t(errorStringId, { error: i18next.t('common:errors.networkError') }))
        return failure({ message: i18next.t('common:errors.networkError'), code: null })
      } else if (!response.isSuccessful) {
        toast.error(i18next.t(errorStringId, { error: responseTextError(response) }))
        return failure({ message: response.message, code: response.statusCode })
      }
      return success(response.data.value)
    }),
    catchError((err) => {
      toast.error(i18next.t(errorStringId, { error: responseFailedTextError(err) }))
      return of(failure(i18next.t(errorStringId, { error: responseFailedTextError(err) })))
    }),
  ]
}

export const responseFailedTextError = (response) => response.message
export const responseTextError = (response) =>
  response.message || response.statusCode || i18next.t('common:errors.apiError')

export const isSuccessful = (response) => response.isSuccessful

export const request = async (endpoint, args, successText = null, errorTextId = null) => {
  try {
    const response = await endpoint(...args)
    if (isSuccessful(response)) {
      successText && toast.success(successText)
      return { response, isSuccessful: true }
    } else {
      errorTextId && toast.error(i18next.t(errorTextId, { error: responseTextError(response) }))
      return { response, isSuccessful: false }
    }
  } catch (err) {
    logger.error('Request error: ', err)
    errorTextId && toast.error(i18next.t(errorTextId, { error: responseTextError(err) }))
    return { response: await err.response, isSuccessful: false }
  }
}

export const setValidationErrors = (response, setErrors) => {
  if (response.status === 400) {
    const errors = {}
    R.forEachObjIndexed((err, field) => (errors[field] = err), response.errors)
    setErrors(errors)
  }
}
