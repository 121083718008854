import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UserType } from '../Types/User'
import { columns } from '../Models/User'
import ChangeListTable from '../../Common/Components/ChangeListTable'
import Loader from '../../Common/Components/Loader'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
`

const UsersList = ({ users, loading, actions }) => {
  // translations
  const { t } = useTranslation()

  return (
    <Container>
      {loading && <Loader />}
      {!loading && (
        <ChangeListTable
          name="users"
          data={users}
          columns={columns(t)}
          sortField="email"
          sortDirection="asc"
          listDisplay={[
            'email',
            'name',
            'surname',
            'role.description',
            'segment.description',
            'domain.description',
            'isEnabled',
          ]}
          actions={actions}
        />
      )}
    </Container>
  )
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(UserType),
  loading: PropTypes.bool,
  actions: PropTypes.array,
}

export default UsersList
