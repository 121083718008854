import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import logger from '../Utils/Logger'
import { FieldType } from '../Types/Field'
import * as R from 'ramda'

const Dropdown = ({ label, value, onChange, required, error, options, helperText, requestAction, width }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (options === undefined) {
      logger.debug(`Dropdown ${label}, undefined options, dispatching action`, requestAction())
      dispatch(requestAction())
    }
  }, [])

  const val = R.ifElse(R.isNil, () => '', R.identity)(value)

  return (
    <FormControl style={{ width }} variant='standard'>
      <InputLabel>
        {label}
        {required && ' *'}
      </InputLabel>
      <Select
        value={val}
        onChange={onChange}
        style={{ width }}
        required
        error={error}
        variant="standard"
      >
        {options && !required && <MenuItem value="">{'--'}</MenuItem>}
        {options &&
          options.map((opt) => (
            <MenuItem key={`opt-${opt.value}`} value={opt.value}>
              {opt.icon ? opt.icon : null} {opt.label}
            </MenuItem>
          ))}
        {!options && <MenuItem value="">{t('common:generic.loadingDots')}</MenuItem>}
      </Select>
      {helperText && <FormHelperText variant='standard'>{helperText}</FormHelperText>}
    </FormControl>
  )
}

Dropdown.defaultProps = {
  width: '100%',
}

Dropdown.propTypes = {
  ...FieldType,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  requestAction: PropTypes.func,
  width: PropTypes.string,
}

export default Dropdown
