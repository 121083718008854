import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const resourcesTypesSlice = createSlice(apiSlice('resourcesTypes', []))

export const { request, success, failure } = resourcesTypesSlice.actions

export default resourcesTypesSlice.reducer

export const selectResourcesTypes = (state) => state.resourcesTypes
export const selectResourcesTypesData = (state) => state.resourcesTypes.data

// side effects
export const fetchResourcesTypesEpic = (action$) =>
  action$.pipe(
    ofType('resourcesTypes/request'),
    mergeMap(() =>
      from(api.resourcesTypes.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'resources:errors.FetchResourcesTypesError',
        ),
      ),
    ),
  )
