import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const productLinesSlice = createSlice(apiSlice('productLines', []))

export const { request, success, failure } = productLinesSlice.actions

export default productLinesSlice.reducer

export const selectProductLines = (state) => state.productLines
export const selectProductLinesData = (state) => state.productLines.data

// side effects
export const fetchProductLinesEpic = (action$) =>
  action$.pipe(
    ofType('productLines/request'),
    mergeMap(() =>
      from(api.productLines.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'core:errors.FetchProductLinesError',
        ),
      ),
    ),
  )
