import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const authSlice = createSlice(apiSlice('auth', {}))

export const { request, success, failure } = authSlice.actions

export default authSlice.reducer

export const selectAuth = state => state.auth.data
