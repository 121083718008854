import i18next from 'i18next'
import * as R from 'ramda'

const required = ['serialNumber', 'deviceTypeId', 'segmentId', 'ownerId']

export const validate = (fields, setErrors) => {
  const err = {}
  const nothing = () => {}

  // check required
  const setRequired = (field) => {
    err[field] = i18next.t('common:validation.requiredField')
  }
  required.forEach(R.ifElse(R.flip(R.prop)(fields), nothing, setRequired))

  const setLengthError = (field, length) => () => {
    err[field] = i18next.t('common:validation.invalidLength', { length })
  }

  // check serial number
  R.ifElse(
    R.compose(R.not, R.equals(9), R.prop('length')),
    setLengthError('serialNumber', 9),
    nothing,
  )(fields.serialNumber)

  R.ifElse(R.compose(R.not, R.equals(15), R.prop('length')), setLengthError('imei', 15), nothing)(fields.imei)

  setErrors(err)
  return R.isEmpty(err)
}
