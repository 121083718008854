import PropTypes from 'prop-types'

export const ResourceJobType = PropTypes.shape({
  id: PropTypes.number,
  startedOn: PropTypes.string,
  finishedOn: PropTypes.string,
  loggedOn: PropTypes.string,
  importProgress: PropTypes.number,
  hasError: PropTypes.bool,
  errorDescription: PropTypes.string,
  errorCode: PropTypes.string,
})
