import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const networksSlice = createSlice(apiSlice('networks', []))

export const { request, success, failure } = networksSlice.actions

export default networksSlice.reducer

export const selectNetworks = (state) => state.networks
export const selectNetworksData = (state) => state.networks.data

// side effects
export const fetchNetworksEpic = (action$) =>
  action$.pipe(
    ofType('networks/request'),
    mergeMap(() =>
      from(api.networks.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'devices:errors.FetchNetworksError',
        ),
      ),
    ),
  )
