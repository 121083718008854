import DevicesReducer, { fetchDevicesEpic } from './Devices'
import DevicesFleetsReducer, { fetchDevicesFleetsEpic } from './DevicesFleets'
import DeviceStatusesReducer, { fetchDeviceStatusesEpic } from './DeviceStatuses'
import DeviceTypesReducer, { fetchDeviceTypesEpic } from './DeviceTypes'
import NetworksReducer, { fetchNetworksEpic } from './Networks'

export const epics = [
  fetchDevicesEpic,
  fetchDevicesFleetsEpic,
  fetchDeviceStatusesEpic,
  fetchDeviceTypesEpic,
  fetchNetworksEpic,
]

export default {
  devices: DevicesReducer,
  devicesFleets: DevicesFleetsReducer,
  deviceStatuses: DeviceStatusesReducer,
  deviceTypes: DeviceTypesReducer,
  networks: NetworksReducer,
}
