import styled from 'styled-components'

export const FlexRow = styled.div`
  align-items: ${props => props.alignCenter ? 'center' : 'flex-start'};
  display: flex;
`

export const FlexColumn = styled.div`
  align-items: ${props => props.alignCenter ? 'center' : 'flex-start'};
  display: flex;
  flex-direction: column;
`
