import i18next from 'i18next'
import * as R from 'ramda'

export const required = [
  'serialNumber',
  'vehicleStatusId',
  'description',
  'plateNumber',
  'segmentId',
  'productLineId',
  'platformId',
  'programId',
  'ownerId',
]

export const validate = (fields, setErrors) => {
  const err = {}
  const nothing = () => {}

  // check required
  const setRequired = (field) => {
    err[field] = i18next.t('common:validation.requiredField')
  }
  required.forEach(R.ifElse(R.flip(R.prop)(fields), nothing, setRequired))

  // check serial number
  const setSerialNumberLengthError = () => {
    err.serialNumber = i18next.t('common:validation.invalidLength', { length: 17 })
  }
  R.ifElse(R.compose(R.not, R.equals(17), R.prop('length')), setSerialNumberLengthError, nothing)(fields.serialNumber)

  setErrors(err)
  return R.isEmpty(err)
}
