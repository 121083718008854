import React from 'react'
import { FieldType } from '../Types/Field'
import MuiTextField from '@mui/material/TextField'
import * as R from 'ramda'

/**
 * A wrpper to make it easy to add functionality, i.e. clearable
 */
const TextField = ({ value, ...props }) => {
  // get rid of "I don't want null" fucking message from MUI
  const val = R.ifElse(R.isNil, () => '', R.identity)(value)
  return <MuiTextField value={val} {...props} variant='standard' />
}

TextField.propTypes = FieldType

export default TextField
