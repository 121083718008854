import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const vehiclesSlice = createSlice(apiSlice('vehicles', []))

export const { request, success, failure } = vehiclesSlice.actions

export default vehiclesSlice.reducer

export const selectVehicles = (state) => state.vehicles
export const selectVehiclesData = (state) => state.vehicles.data

// side effects
export const fetchVehiclesEpic = (action$) =>
  action$.pipe(
    ofType('vehicles/request'),
    mergeMap(() =>
      from(api.vehicles.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'vehicles:errors.FetchVehiclesError',
        ),
      ),
    ),
  )
