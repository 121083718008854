import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../Auth/Redux/Auth'
import { selectUsersData } from '../../../Auth/Redux/Users'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import VehicleStatusDropdown from '../../Components/VehicleStatusDropdown'
import SegmentDropdown from '../../../Common/Components/SegmentDropdown'
import ProductLineDropdown from '../../../Common/Components/ProductLineDropdown'
import PlatformDropdown from '../../../Common/Components/PlatformDropdown'
import ProgramField from '../../../Common/Components/ProgramField'
import GpdPhaseDropdown from '../../../Common/Components/GpdPhaseDropdown'
import EngineTypeDropdown from '../../Components/EngineTypeDropdown'
import EmissionLevelDropdown from '../../Components/EmissionLevelDropdown'
import ModelField from '../../Components/ModelField'
import SiteDropdown from '../../../Common/Components/SiteDropdown'
import UserField from '../../../Auth/Components/UserField'
import { setStateFromEvent } from '../../../Common/Utils/Events'
import Button from '@mui/material/Button'
import TextField from '../../../Common/Components/TextField'
import { VehicleType } from '../../Types/Vehicle'
import * as R from 'ramda'
import logger from '../../../Common/Utils/Logger'
import { validate } from './Validation'
import { FormRow, FormColControl } from '../../../Common/Components/Forms'
import { propOrDefault } from '../../../Common/Utils/Objects'

const VehicleForm = ({ onClose, onSave, vehicle }) => {
  const { t } = useTranslation()
  const authData = useSelector(selectAuth)
  const users = useSelector(selectUsersData)
  const authUserId = R.ifElse(
    R.isNil,
    R.always(null),
    R.prop('id'),
  )(R.find(R.propEq('email', authData.username), users))

  const [fields, setFields] = React.useState({
    serialNumber: propOrDefault(vehicle, 'serialNumber'),
    vehicleStatusId: propOrDefault(vehicle, 'vehicleStatusId'),
    description: propOrDefault(vehicle, 'description'),
    plateNumber: propOrDefault(vehicle, 'plateNumber'),
    segmentId: propOrDefault(vehicle, 'segmentId'),
    productLineId: propOrDefault(vehicle, 'productLineId'),
    platformId: propOrDefault(vehicle, 'platformId'),
    programId: propOrDefault(vehicle, 'programId'),
    gpdPhaseId: propOrDefault(vehicle, 'gpdPhaseId'),
    ctsVehicleId: propOrDefault(vehicle, 'ctsVehicleId'),
    engineTypeId: propOrDefault(vehicle, 'engineTypeId'),
    emissionLevelId: propOrDefault(vehicle, 'emissionLevelId'),
    modelId: propOrDefault(vehicle, 'modelId'),
    pvResponsible: propOrDefault(vehicle, 'pvResponsible'),
    siteId: propOrDefault(vehicle, 'siteId'),
    ownerId: propOrDefault(vehicle, 'ownerId', authUserId),
    notes: propOrDefault(vehicle, 'notes'),
  })
  const [errors, setErrors] = React.useState({})

  const setField = (field) => (value) => {
    // @TODO validate on field change??
    // set value
    setFields({ ...fields, [field]: value })
  }

  const submit = () => {
    logger.debug('Vehicle form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('Vehicle form submission, validation passed, saving')
      onSave(fields)
    } else {
      logger.debug('Vehicle form submission, validation failed')
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t(`vehicles:ui.${vehicle ? 'Edit' : 'Add'}Vehicle`)}</DialogTitle>
      <DialogContent>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('vehicles:fields.serialNumber')}
              value={fields.serialNumber}
              onChange={setStateFromEvent(setField('serialNumber'))}
              error={!!errors.serialNumber}
              helperText={errors.serialNumber}
            />
          </FormColControl>
          <FormColControl>
            <VehicleStatusDropdown
              required
              value={fields.vehicleStatusId}
              onChange={setStateFromEvent(setField('vehicleStatusId'))}
              error={!!errors.vehicleStatusId}
              helperText={errors.vehicleStatusId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('vehicles:fields.description')}
              value={fields.description}
              onChange={setStateFromEvent(setField('description'))}
              error={!!errors.description}
              helperText={errors.description}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              required
              label={t('vehicles:fields.plateNumber')}
              value={fields.plateNumber}
              onChange={setStateFromEvent(setField('plateNumber'))}
              error={!!errors.plateNumber}
              helperText={errors.plateNumber}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <SegmentDropdown
              required
              value={fields.segmentId}
              onChange={setStateFromEvent(setField('segmentId'))}
              error={!!errors.segmentId}
              helperText={errors.segmentId}
            />
          </FormColControl>
          <FormColControl>
            <ProductLineDropdown
              required
              value={fields.productLineId}
              onChange={setStateFromEvent(setField('productLineId'))}
              error={!!errors.productLineId}
              helperText={errors.productLineId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <PlatformDropdown
              required
              value={fields.platformId}
              onChange={setStateFromEvent(setField('platformId'))}
              productLineId={fields.productLineId}
              error={!!errors.platformId}
              helperText={errors.platformId}
            />
          </FormColControl>
          <FormColControl>
            <ProgramField
              required
              value={fields.programId}
              onChange={setField('programId')}
              error={!!errors.programId}
              helperText={errors.programId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <GpdPhaseDropdown
              value={fields.gpdPhaseId}
              onChange={setStateFromEvent(setField('gpdPhaseId'))}
              error={!!errors.gpdPhaseId}
              helperText={errors.gpdPhaseId}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              label={t('vehicles:fields.ctsVehicleId')}
              value={fields.ctsVehicleId}
              onChange={setStateFromEvent(setField('ctsVehicleId'))}
              error={!!errors.ctsVehicleId}
              helperText={errors.ctsVehicleId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <EngineTypeDropdown
              value={fields.engineTypeId}
              onChange={setStateFromEvent(setField('engineTypeId'))}
              error={!!errors.engineTypeId}
              helperText={errors.engineTypeId}
            />
          </FormColControl>
          <FormColControl>
            <EmissionLevelDropdown
              value={fields.emissionLevelId}
              onChange={setStateFromEvent(setField('emissionLevelId'))}
              error={!!errors.emissionLevelId}
              helperText={errors.emissionLevelId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <ModelField
              value={fields.modelId}
              onChange={setField('modelId')}
              error={!!errors.modelId}
              helperText={errors.modelId}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              label={t('vehicles:fields.pvResponsible')}
              value={fields.pvResponsible}
              onChange={setStateFromEvent(setField('pvResponsible'))}
              error={!!errors.pvResponsible}
              helperText={errors.pvResponsible}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <SiteDropdown
              value={fields.siteId}
              onChange={setStateFromEvent(setField('siteId'))}
              error={!!errors.siteId}
              helperText={errors.siteId}
            />
          </FormColControl>
          <FormColControl>
            <UserField
              required
              label={t('vehicles:fields.owner.__field')}
              value={fields.ownerId}
              onChange={setField('ownerId')}
              error={!!errors.ownerId}
              helperText={errors.ownerId}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <TextField
              label={t('vehicles:fields.notes')}
              value={fields.notes}
              onChange={setStateFromEvent(setField('notes'))}
              error={!!errors.notes}
              helperText={errors.notes}
            />
          </FormColControl>
          <FormColControl />
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('common:buttons.Cancel')}
        </Button>
        <Button onClick={submit} color="primary">
          {t('common:buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

VehicleForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  vehicle: VehicleType,
}

export default VehicleForm
