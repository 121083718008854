import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectVehicles } from '../Redux/Vehicles'
import TextField from '../../Common/Components/TextField'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ChangeListTable from '../../Common/Components/ChangeListTable'
import VehicleForm from '../Forms/VehicleForm'
import Loader from '../../Common/Components/Loader'
import { columns } from '../Models/Vehicle'
import api from '../../Core/Services/Api'
import { request } from '../../Common/Utils/Api'
import styled from 'styled-components'
import { setStateFromEvent } from '../../Common/Utils/Events'
import { styled as muistyled } from '@mui/system'

const Wrapper = styled.div`
  padding-bottom: 70px;
`
const FilterForm = styled.div`
  padding: 1rem;
`
const AddFab = muistyled(Fab)`
    position: fixed;
    bottom: 20px;
    left: 340px;
`

const VehiclesList = () => {
  const { t } = useTranslation()
  const vehicles = useSelector(selectVehicles).data
  const isLoading = useSelector(selectVehicles).fetching
  const [filterValue, setFilterValue] = React.useState('')

  const [form, setForm] = React.useState({ isOpen: false, vehicle: null })

  // selected records
  const [selected, setSelected] = React.useState([])

  const handleSaveVehicle = async (vehicle) => {
    const res = await request(
      api.vehicles.create,
      [vehicle],
      t('vehicles:success.VehicleCreated', { vehicle: vehicle.description }),
      'vehicles:errors.CreateVehicleError',
    )
    if (res.isSuccessful) {
      // update vehicles and close modal
    }
  }

  const handleEdit = (vehicle) => {
    setForm({ isOpen: true, vehicle })
  }

  const handleDelete = (vehicle) => {
    console.log(vehicle, 'DELETE')
  }

  const actions = [
    { id: 'EDIT', label: t('common:generic.Edit'), cb: handleEdit, icon: <EditIcon /> },
    { id: 'DELETE', label: t('common:generic.Delete'), cb: handleDelete, icon: <DeleteIcon /> },
  ]

  return (
    <Wrapper>
      <FilterForm>
        <TextField
          label={t('common:generic.Filter')}
          style={{ width: '100%' }}
          value={filterValue}
          onChange={setStateFromEvent(setFilterValue)}
        />
      </FilterForm>
      {isLoading && <Loader minHeight="200px" />}
      {!isLoading && (
        <ChangeListTable
          noSettings
          name="vehicles"
          data={vehicles.filter(
            (v) => !filterValue || (v.description && v.description.toLowerCase().includes(filterValue)),
          )}
          selectable
          selected={selected}
          columns={columns(t)}
          sortField="description"
          sortDirection="asc"
          listDisplay={['description']}
          onSelect={setSelected}
          actions={actions}
        />
      )}
      <AddFab
        color="primary"
        aria-label={t('common:generic.add')}
        onClick={() => setForm({ isOpen: true, vehicle: null })}
      >
        <AddIcon style={{ cursor: 'pointer' }} />
      </AddFab>
      {form.isOpen && (
        <VehicleForm
          onClose={() => setForm({ isOpen: false, vehicle: null })}
          vehicle={form.vehicle}
          onSave={handleSaveVehicle}
        />
      )}
    </Wrapper>
  )
}

VehiclesList.propTypes = {}

export default VehiclesList
