import React from 'react'
// import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import history from '../history'
import config from '../Config'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../../Common/Components/PrivateRoute'

// Redux
import { request as vehiclesRequest } from '../../Vehicles/Redux/Vehicles'
import { request as vehiclesFleetsRequest } from '../../Vehicles/Redux/VehiclesFleets'
import { request as vehicleStatusesRequest } from '../../Vehicles/Redux/VehicleStatuses'
import { request as engineTypesRequest } from '../../Vehicles/Redux/EngineTypes'
import { request as emissionLevelsRequest } from '../../Vehicles/Redux/EmissionLevels'
import { request as modelsRequest } from '../../Vehicles/Redux/Models'
import { request as devicesRequest } from '../../Devices/Redux/Devices'
import { request as devicesFleetsRequest } from '../../Devices/Redux/DevicesFleets'
import { request as deviceStatusesRequest } from '../../Devices/Redux/DeviceStatuses'
import { request as deviceTypesRequest } from '../../Devices/Redux/DeviceTypes'
import { request as resourcesRequest } from '../../Resources/Redux/Resources'
import { request as resourcesTypesRequest } from '../../Resources/Redux/ResourcesTypes'
import { request as resourcesJobsRequest } from '../../Resources/Redux/ResourcesJobs'
// Permissions
import { VehiclesPermissions, DevicesPermissions, UsersPermissions, ResourcesPermissions } from '../../Auth/Permissions'
// Views
import LoginView from '../../Auth/Views/LoginView'
import HomeView from '../Views/HomeView'
// Apps
import VehiclesApp from '../../Vehicles'
import DevicesApp from '../../Devices'
import AuthApp from '../../Auth'
import ResourcesApp from '../../Resources'

/**
 * This component defines all the application routes, public and private ones.
 * Implement the right shouldDispatch functions!
 */
class AppRouter extends React.Component {
  render () {
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path={config.urls.login} component={LoginView} />
          <PrivateRoute exact path={config.urls.home} component={HomeView} />
          <PrivateRoute
            path={config.urls.vehicles}
            component={VehiclesApp}
            actions={[
              [vehiclesRequest(), (state) => state.vehicles.data.length === 0],
              [vehiclesFleetsRequest(), (state) => state.vehiclesFleets.data.length === 0],
              [vehicleStatusesRequest(), (state) => state.vehicleStatuses.data.length === 0],
              [engineTypesRequest(), (state) => state.engineTypes.data.length === 0],
              [emissionLevelsRequest(), (state) => state.emissionLevels.data.length === 0],
              [modelsRequest(), (state) => state.models.data.length === 0],
            ]}
            permFunction={VehiclesPermissions.view}
          />
          <PrivateRoute
            path={config.urls.devices}
            component={DevicesApp}
            actions={[
              [devicesRequest(), (state) => state.devices.data.length === 0],
              [devicesFleetsRequest(), (state) => state.devicesFleets.data.length === 0],
              [deviceStatusesRequest(), (state) => state.deviceStatuses.data.length === 0],
              [deviceTypesRequest(), (state) => state.deviceTypes.data.length === 0],
            ]}
            permFunction={DevicesPermissions.view}
          />
          <PrivateRoute
            path={config.urls.users}
            component={AuthApp}
            actions={[]}
            permFunction={UsersPermissions.view}
          />
          <PrivateRoute
            path={config.urls.resources}
            component={ResourcesApp}
            actions={[
              [resourcesRequest(), (state) => state.resources.data.length === 0],
              [resourcesTypesRequest(), (state) => state.resourcesTypes.data.length === 0],
              [resourcesJobsRequest(), (state) => state.resourcesJobs.data.length === 0],
            ]}
            permFunction={ResourcesPermissions.view}
          />
        </Switch>
      </ConnectedRouter>
    )
  }
}

export default AppRouter
