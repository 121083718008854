import React from 'react'
import ConfirmationDialog from '../Components/ConfirmationDialog'

export const useConfirm = onConfirm => {
  const [state, setState] = React.useState({ open: false })
  const open = (payload, title, content) => {
    setState({ open: true, title, content, payload })
  }
  const close = () => {
    setState({ open: false })
  }
  const handleConfirm = React.useCallback(() => onConfirm(state.payload), [onConfirm, state.payload])

  const confirm = (
    <ConfirmationDialog
      open={state.open}
      title={state.title}
      onCancel={() => setState({ open: false })}
      onOk={handleConfirm}
    >
      {state.content}
    </ConfirmationDialog>
  )

  return [open, close, confirm]
}
