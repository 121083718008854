import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const usersSlice = createSlice(apiSlice('users', []))

export const { request, success, failure } = usersSlice.actions

export default usersSlice.reducer

export const selectUsers = (state) => state.users
export const selectUsersData = (state) => state.users.data

// side effects
export const fetchUsersEpic = (action$) =>
  action$.pipe(
    ofType('users/request'),
    mergeMap(() =>
      from(api.users.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'auth:errors.FetchUsersError',
        ),
      ),
    ),
  )
