import React from 'react'
import PropTypes from 'prop-types'
import * as signalR from '@microsoft/signalr'
import logger from '../Utils/Logger'
import api from '../../Core/Services/Api'
import { toast } from 'react-toastify'
import i18next from 'i18next'

class Signalr extends React.Component {
  constructor (props) {
    super(props)
    this.connectionClosed = 'CONNECTION CLOSED'
    this.maxNumberMessage = 20
    this.isStarted = false
    this.isRunning = false
  }

  shouldComponentUpdate () {
    return false
  }

  componentDidMount () {
    this.startConnection()
  }

  async startConnection () {
    if (!this.isStarted) {
      this.isStarted = true
      logger.info('Singlar, getting coordinates', this.props.hubName)
      try {
        const response = await this.getCoordinates()
        if (response.isSuccessful) {
          logger.debug('Singlar, setting hub connection', this.props.hubName)
          this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(response.data.url, {
              accessTokenFactory: () => {
                return response.data.accessToken
              },
            })
            .build()
          logger.debug('Singlar, starting hub connection', this.props.hubName)
          // start connection
          this.hubConnection
            .start()
            .then(() => logger.debug('Signalr, connection started'))
            .catch((err) => logger.error('Signalr, error while starting connection', err))

          // wait for messages
          logger.debug('Waiting for messages on hub', this.props.hubName)
          this.hubConnection.on(this.props.hubName, (data) => {
            this.props.listener(data)
          })

          this.hubConnection.onclose(() => {
            logger.info('Signalr, connection closed', this.props.hubName)
            this.isRunning = false
          })
        } else {
          toast.error(i18next.t('common:errors.SignalrConnectionError'))
          logger.error('Singlar, getting coordinates error', response)
        }
      } catch (e) {
        toast.error(i18next.t('common:errors.SignalrConnectionError'))
        logger.error('Singlar, getting coordinates error', e)
      }
    }
  }

  stopConnection () {
    if (this.hubConnection != null && this.hubConnection !== undefined) {
      logger.debug('Signalr, closing connection', this.props.hubName)
      this.hubConnection.stop()
      this.isStarted = false
      this.isRunning = false
    }
  }

  getCoordinates () {
    return api.signalr[this.props.hubName]()
  }

  render () {
    return null
  }

  componentWillUnmount () {
    this.stopConnection()
  }
}

Signalr.propTypes = {
  hubName: PropTypes.string,
  listener: PropTypes.func.isRequired,
}

export default Signalr
