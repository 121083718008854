import React from 'react'
import PropTypes from 'prop-types'
import store from '../../Core/Redux/Store'
import config from '../../Core/Config'
import { Route, Redirect } from 'react-router-dom'

/**
 * A decorator for private routes which redirects to login
 * if user is not authenticated
 * @param Component: the component to render
 * @param permFunction: a function to check user permissions for the view, receives the user object, must return a bool
 * @param actions: actions to be dispatched, in the form [action, shouldDispatch]
 *                 action is the action to dispatch
 *                 shouldDispatch is a function that receives the application state and returns true
 *                 if the action should be dispatched, false otherwise
 */
const PrivateRoute = ({
  component: Component,
  componentProps = {},
  permFunction,
  actions = [],
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const state = store.getState()
      if (state.auth.data.username && (!permFunction || permFunction(state.auth.user, props.match.params))) {
        // actions that needs to be dispatched before entering the view
        if (actions && actions.length) {
          actions.forEach(([action, shouldDispatch, matchParam]) => {
            if (shouldDispatch(state)) {
              store.dispatch(matchParam ? action(props.match.params[matchParam]) : action)
            }
          })
        }
        return <Component {...props} {...componentProps} />
      } else {
        const redirectPath = config.urls.login
        return (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location },
            }}
          />
        )
      }
    }}
  />
)
PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  componentProps: PropTypes.object,
  location: PropTypes.object,
  permFunction: PropTypes.func,
  actions: PropTypes.array,
  match: PropTypes.object,
}

export default PrivateRoute
