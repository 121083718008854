import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as productLinesRequest, selectProductLinesData } from '../../Core/Redux/ProductLines'
import { FieldType } from '../Types/Field'
import Dropdown from './Dropdown'

const ProductLineDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const productLines = useSelector(selectProductLinesData)

  return (
    <Dropdown
      label={label || t('common:fields.productLine')}
      required={required}
      value={value}
      onChange={onChange}
      options={productLines.length ? productLines.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={productLinesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

ProductLineDropdown.defaultProps = {
  required: false,
}

ProductLineDropdown.propTypes = FieldType

export default ProductLineDropdown
