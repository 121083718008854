import PropTypes from 'prop-types'

export const ResourceType = PropTypes.shape({
  id: PropTypes.number,
  guid: PropTypes.string,
  archived: PropTypes.bool,
  fileName: PropTypes.string,
  ecuDataFileTypeId: PropTypes.number,
  fileSize: PropTypes.number,
  importCompleted: PropTypes.bool,
  url: PropTypes.string,
  tag: PropTypes.string,
  version: PropTypes.string,
  userId: PropTypes.number,
  createdBy: PropTypes.string,
  createdOn: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string,
})
