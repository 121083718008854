export const VehiclesPermissions = {
  view: user => true,
  create: user => true,
  update: user => true,
  delete: user => true,
}

export const DevicesPermissions = {
  view: user => true,
  create: user => true,
  update: user => true,
  delete: user => true,
}

export const UsersPermissions = {
  view: user => true,
  create: user => true,
  update: user => true,
  delete: user => true,
}

export const ResourcesPermissions = {
  view: user => true,
  create: user => true,
  update: user => true,
  delete: user => true,
}
