import { createSlice } from '@reduxjs/toolkit'
import api from '../../Core/Services/Api'
import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { manageEpicResponse } from '../../Common/Utils/Api'
import { apiSlice } from '../../Common/Utils/Redux'

// reducer
const devicesSlice = createSlice(apiSlice('devices', []))

export const { request, success, failure } = devicesSlice.actions

export default devicesSlice.reducer

export const selectDevices = (state) => state.devices
export const selectDevicesData = (state) => state.devices.data

// side effects
export const fetchDevicesEpic = (action$) =>
  action$.pipe(
    ofType('devices/request'),
    mergeMap(() =>
      from(api.devices.get()).pipe(
        ...manageEpicResponse(
          success,
          failure,
          'devices:errors.FetchDevicesError',
        ),
      ),
    ),
  )
