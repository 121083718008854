import VehiclesReducer, { fetchVehiclesEpic } from './Vehicles'
import VehiclesFleetsReducer, { fetchVehiclesFleetsEpic } from './VehiclesFleets'
import VehicleStatusesReducer, { fetchVehicleStatusesEpic } from './VehicleStatuses'
import EngineTypesReducer, { fetchEngineTypesEpic } from './EngineTypes'
import EmissionLevelsReducer, { fetchEmissionLevelsEpic } from './EmissionLevels'
import ModelsReducer, { fetchModelsEpic } from './Models'

export const epics = [
  fetchVehiclesEpic,
  fetchVehiclesFleetsEpic,
  fetchVehicleStatusesEpic,
  fetchEngineTypesEpic,
  fetchEmissionLevelsEpic,
  fetchModelsEpic,
]

export default {
  vehicles: VehiclesReducer,
  vehiclesFleets: VehiclesFleetsReducer,
  vehicleStatuses: VehicleStatusesReducer,
  engineTypes: EngineTypesReducer,
  emissionLevels: EmissionLevelsReducer,
  models: ModelsReducer,
}
