import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" style={style}><path fill={color} d="M12,3l8,6v12h-5v-7H9v7H4V9L12,3z"/></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
