import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as deviceStatusesRequest, selectDeviceStatusesData } from '../Redux/DeviceStatuses'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const DeviceStatusDropdown = ({ value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const deviceStatuses = useSelector(selectDeviceStatusesData)

  return (
    <Dropdown
      label={t('devices:fields.deviceStatus.__field')}
      required={required}
      value={value}
      onChange={onChange}
      options={deviceStatuses.length ? deviceStatuses.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={deviceStatusesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

DeviceStatusDropdown.defaultProps = {
  required: false,
}

DeviceStatusDropdown.propTypes = FieldType

export default DeviceStatusDropdown
