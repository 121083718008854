import * as R from 'ramda'

export default {
  get (key, defaultValue = null) {
    const val = JSON.parse(localStorage.getItem(`PROMETHEUS_${key}`))
    return R.ifElse(R.isNil, R.always(defaultValue), R.always(val))(val)
  },
  save (key: string, value: any) {
    return localStorage.setItem(`PROMETHEUS_${key}`, JSON.stringify(value))
  },
}
