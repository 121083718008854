import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import DevicesList from './DevicesList'
import DevicesFleetsList from './DevicesFleetsList'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 350px;
`

const VehiclesSidebar = () => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  return (
    <Wrapper>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t('common:generic.Devices')} />
        <Tab label={t('common:generic.Fleets')} />
      </Tabs>
      {value === 0 && (
        <DevicesList />
      )}
      {value === 1 && (
        <DevicesFleetsList />
      )}
    </Wrapper>
  )
}

VehiclesSidebar.propTypes = {}

export default VehiclesSidebar
