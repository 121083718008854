import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { request as modelsRequest, selectModelsData } from '../Redux/Models'
import { FieldType } from '../../Common/Types/Field'
import Autocomplete from '../../Common/Components/Autocomplete'
import * as R from 'ramda'

// @TODO freeSolo and create program on blur
const ModelField = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const models = useSelector(selectModelsData)
  const modelsMap = React.useMemo(() => models.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}), [models])
  const getModelLabel = m => m.description
  const getModelOption = m => ({ value: m.id, label: getModelLabel(m) })

  const val = R.ifElse(
    R.prop(value),
    R.pipe(R.prop(value), getModelOption),
    R.always(null),
  )(modelsMap)

  return (
    <Autocomplete
      freeSolo
      label={label || t('vehicles:fields.model.__field')}
      required={required}
      value={val}
      onChange={onChange}
      options={models.map(getModelOption)}
      requestAction={modelsRequest}
      error={error}
      helperText={helperText}
    />
  )
}

ModelField.defaultProps = {
  required: false,
}

ModelField.propTypes = FieldType

export default ModelField
