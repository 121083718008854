import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectUsersData, selectUsers, request as usersRequest } from '../Redux/Users'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../../Common/Components/Flex'
import ConfirmationDialog from '../../Common/Components/ConfirmationDialog'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import UsersSidebar from '../Components/UsersSidebar'
import UsersList from '../Components/UsersList'
import UserForm from '../Forms/UserForm'
import BaseLayout from '../../Core/Layouts/BaseLayout'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { propOrDefault } from '../../Common/Utils/Objects'
import config from '../../Core/Config'
import api from '../../Core/Services/Api'
import { request } from '../../Common/Utils/Api'
import * as R from 'ramda'
import { styled as muistyled } from '@mui/system'

const AddFab = muistyled(Fab)`
    position: fixed;
    bottom: 2rem;
    right: 2rem;
`

const AdminUsersView = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const users = useSelector(selectUsersData)
  const fetched = useSelector(selectUsers).fetched
  const breadcrumbs = [{ label: t('core:navigation.Users'), handleClick: () => history.push(config.urls.users) }]

  // record actions
  const handleEdit = (user) => {
    setForm({ isOpen: true, user })
  }

  const handleDelete = (user) => {
    setDeleting({ isOpen: true, user })
  }

  const actions = [
    { id: 'EDIT', label: t('common:generic.Edit'), cb: handleEdit, icon: <EditIcon /> },
    { id: 'DELETE', label: t('common:generic.Delete'), cb: handleDelete, icon: <DeleteIcon /> },
  ]

  // sidebar form
  const [fields, setFields] = React.useState({
    email: null,
    surname: null,
    segmentId: null,
    isEnabled: null,
  })

  // insert, edit form
  const [form, setForm] = React.useState({ isOpen: false, user: null })
  const [deleting, setDeleting] = React.useState({ isOpen: false, user: null })

  const handleSaveUser = async (user) => {
    const id = R.prop('email')(form.user)
    const res = await request(
      api.users.createOrUpdate,
      [id ? { ...user, email: undefined } : user, id],
      t(`auth:success.User${id ? 'Updated' : 'Created'}`, {
        user: user.email,
      }),
      `auth:errors.${id ? 'UpdateUserError' : 'CreateUserError'}`,
    )
    if (res.isSuccessful) {
      dispatch(usersRequest())
      setForm({ isOpen: false, users: null })
    }
    return res
  }

  const handleDeleteUser = async () => {
    const id = R.prop('email')(deleting.user)
    const { isSuccessful } = await request(
      api.users.delete,
      [id],
      t('auth:success.UserDeleted', {
        user: deleting.user.email,
      }),
      'auth:errors.DeleteUserError',
    )
    if (isSuccessful) {
      dispatch(usersRequest())
      setDeleting({ isOpen: false, user: null })
    }
  }

  const handleFieldChange = (field, value) => {
    setFields({ ...fields, [field]: value })
  }

  const filteredUsers = users.filter((u) => {
    if (fields.email && !propOrDefault(u, 'email', '').toLowerCase().includes(fields.email.toLowerCase())) return false
    if (fields.surname && !propOrDefault(u, 'surname', '').toLowerCase().includes(fields.surname.toLowerCase())) {
      return false
    }
    if (fields.segmentId && u.segmentId !== fields.segmentId) return false
    if (typeof fields.isEnabled === 'boolean' && u.isEnabled !== fields.isEnabled) return false
    return true
  })

  return (
    <BaseLayout
      showMenu
      breadcrumbs={breadcrumbs}
      sidebar={
        <UsersSidebar
          fields={fields}
          onFieldChange={handleFieldChange}
          totalFiltered={filteredUsers.length}
          total={users.length}
          loading={!fetched}
        />
      }
    >
      <FlexRow style={{ padding: '1rem' }}>
        <UsersList users={filteredUsers} actions={actions} loading={!fetched} />
        {form.isOpen && (
          <UserForm onClose={() => setForm({ isOpen: false, user: null })} user={form.user} onSave={handleSaveUser} />
        )}
        {deleting.isOpen && (
          <ConfirmationDialog
            open
            title={t('auth:ui.DeleteUserConfirmationTitle')}
            text={t('auth:ui.DeleteUserConfirmationText', { user: deleting.user.email })}
            onCancel={() => setDeleting({ isOpen: false, user: null })}
            onOk={handleDeleteUser}
          />
        )}
        <AddFab
          color="primary"
          aria-label={t('common:generic.add')}
          onClick={() => setForm({ isOpen: true, user: null })}
        >
          <AddIcon style={{ cursor: 'pointer' }} />
        </AddFab>
      </FlexRow>
    </BaseLayout>
  )
}

export default AdminUsersView
