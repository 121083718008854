export default {
  logger: {
    level: process.env.REACT_APP_LOGGER_LEVEL, // INFO, WARNING, ERROR
  },
  // apiBasePath: 'http://localhost:5000/api',
  apiBasePath: 'https://portal-dev.prometheus.cnhind.com/api',
  urls: {
    home: '/',
    login: '/login',
    vehicles: '/vehicles',
    devices: '/devices',
    users: '/users',
    resources: '/resources',
  },
}
