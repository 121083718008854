export const columns = (t) => [
  {
    id: 'id',
    label: t('devices:fields.id'),
    numeric: true,
    width: '40px',
  },
  {
    id: 'activated',
    label: t('devices:fields.activated'),
    boolean: true,
  },
  {
    id: 'createdBy',
    label: t('devices:fields.createdBy'),
  },
  {
    id: 'createdOn',
    label: t('devices:fields.createdOn'),
    datetime: true,
  },
  {
    id: 'updatedBy',
    label: t('devices:fields.updatedBy'),
  },
  {
    id: 'updatedOn',
    label: t('devices:fields.updatedOn'),
    datetime: true,
  },
  {
    id: 'description',
    label: t('devices:fields.description'),
  },
  {
    id: 'deviceStatusId',
    label: t('devices:fields.deviceStatusId'),
  },
  {
    id: 'deviceStatus.code',
    label: t('devices:fields.deviceStatus.code'),
  },
  {
    id: 'deviceStatus.description',
    label: t('devices:fields.deviceStatus.description'),
  },
  {
    id: 'deviceTypeId',
    label: t('devices:fields.deviceTypeId'),
  },
  {
    id: 'deviceType.code',
    label: t('devices:fields.deviceType.code'),
  },
  {
    id: 'deviceType.description',
    label: t('devices:fields.deviceType.description'),
  },
  {
    id: 'imei',
    label: t('devices:fields.imei'),
  },
  {
    id: 'manufacturingDate',
    label: t('devices:fields.manufacturingDate'),
    datetime: true,
  },
  {
    id: 'networkId',
    label: t('devices:fields.networkId'),
  },
  {
    id: 'network.code',
    label: t('devices:fields.network.code'),
  },
  {
    id: 'network.description',
    label: t('devices:fields.network.description'),
  },
  {
    id: 'notes',
    label: t('devices:fields.notes'),
  },
  {
    id: 'ownerId',
    label: t('devices:fields.ownerId'),
  },
  {
    id: 'pvResponsible',
    label: t('devices:fields.pvResponsible'),
  },
  {
    id: 'segment.code',
    label: t('devices:fields.segment.code'),
  },
  {
    id: 'segment.description',
    label: t('devices:fields.segment.description'),
  },
  {
    id: 'serialNumber',
    label: t('devices:fields.serialNumber'),
  },
  {
    id: 'simId',
    label: t('devices:fields.simId'),
  },
  {
    id: 'siteId',
    label: t('devices:fields.siteId'),
  },
  {
    id: 'vehicleId',
    label: t('devices:fields.vehicleId'),
  },
  {
    id: 'vehicle.description',
    label: t('devices:fields.vehicle.description'),
  },
  {
    id: 'vehicle.serialNumber',
    label: t('devices:fields.vehicle.serialNumber'),
  },
]
