import React from 'react'
import Bg from './loading-bg.jpg'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const Background = styled.div`
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  height: 100vh;
`

const Opacity = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, .8);
  display: flex;
  height: 100vh;
  justify-content: center;
`

const Loading = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  justify-content: center;

  span {
    margin-top: 1rem;
  }
`

const LoadingView = () => {
  const { t } = useTranslation()

  return (
    <Background>
      <Opacity>
        <Loading>
          <CircularProgress color='secondary' />
          <span>{t('common:generic.Loading')}</span>
        </Loading>
      </Opacity>
    </Background>
  )
}

export default LoadingView
