import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../Auth/Redux/Auth'
import { selectUsersData } from '../../../Auth/Redux/Users'
import ChangeListTable from '../../../Common/Components/ChangeListTable'
import { columns as vehicleColumns } from '../../Models/Vehicle'
import Loader from '../../../Common/Components/Loader'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SegmentDropdown from '../../../Common/Components/SegmentDropdown'
import UserField from '../../../Auth/Components/UserField'
import Alert from '@mui/material/Alert'
import { setStateFromEvent } from '../../../Common/Utils/Events'
import Button from '@mui/material/Button'
import TextField from '../../../Common/Components/TextField'
import { VehicleType } from '../../Types/Vehicle'
import { VehiclesFleetType } from '../../Types/VehiclesFleet'
import styled from 'styled-components'
import * as R from 'ramda'
import logger from '../../../Common/Utils/Logger'
import api from '../../../Core/Services/Api'
import { request, setValidationErrors } from '../../../Common/Utils/Api'
import { validate } from './Validation'
import { FormRow, FormColControl } from '../../../Common/Components/Forms'
import { propOrDefault } from '../../../Common/Utils/Objects'

const FilterForm = styled.div`
  margin-bottom: 1rem;
`

const VehiclesFleetForm = ({ onClose, onSave, vehiclesFleet, vehicles }) => {
  const { t } = useTranslation()
  const authData = useSelector(selectAuth)
  const users = useSelector(selectUsersData)
  const [isLoadingVehicles, setIsLoadingVehicles] = React.useState(false)

  const [filterValue, setFilterValue] = React.useState('')

  React.useEffect(async () => {
    if (vehiclesFleet) {
      setIsLoadingVehicles(true)
      const { response, isSuccessful } = await request(
        api.vehiclesFleets.get,
        [vehiclesFleet.id],
        null,
        'vehicles.errors.FetchVehiclesFleetError',
      )
      setIsLoadingVehicles(false)
      if (isSuccessful) {
        setSelected(response.data.vehicles)
      }
    }
  }, [vehiclesFleet])

  const [selected, setSelected] = React.useState([])

  const authUserId = R.ifElse(
    R.isNil,
    R.always(null),
    R.prop('id'),
  )(R.find(R.propEq('email', authData.username), users))

  const [fields, setFields] = React.useState({
    name: propOrDefault(vehiclesFleet, 'name'),
    description: propOrDefault(vehiclesFleet, 'description'),
    segmentId: propOrDefault(vehiclesFleet, 'segmentId'),
    ownerId: propOrDefault(vehiclesFleet, 'ownerId', authUserId),
    notes: propOrDefault(vehiclesFleet, 'notes'),
  })
  const [errors, setErrors] = React.useState({})

  const setField = (field) => (value) => {
    setFields({ ...fields, [field]: value })
  }

  const submit = async () => {
    logger.debug('Vehicles fleet form submission, fields:', fields)
    if (validate(fields, setErrors)) {
      logger.debug('Vehicles fleet form submission, validation passed, saving')
      const { response, isSuccessful } = await onSave({ ...fields, vehicles: selected.map((v) => v.serialNumber) })
      if (!isSuccessful) {
        setValidationErrors(response, setErrors)
      }
    } else {
      logger.debug('Vehicles fleet form submission, validation failed')
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t(`vehicles:ui.${vehiclesFleet ? 'Edit' : 'Add'}VehiclesFleet`)}</DialogTitle>
      <DialogContent>
        <FormRow>
          <FormColControl>
            <TextField
              required
              label={t('vehicles:fields.name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              required
              label={t('vehicles:fields.description')}
              value={fields.description}
              onChange={setStateFromEvent(setField('description'))}
              error={!!errors.description}
              helperText={errors.description}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <UserField
              required
              label={t('vehicles:fields.owner.__field')}
              value={fields.ownerId}
              onChange={setField('ownerId')}
              error={!!errors.ownerId}
              helperText={errors.ownerId}
            />
          </FormColControl>
          <FormColControl>
            <TextField
              label={t('vehicles:fields.notes')}
              value={fields.notes}
              onChange={setStateFromEvent(setField('notes'))}
              error={!!errors.notes}
              helperText={errors.notes}
            />
          </FormColControl>
        </FormRow>
        <FormRow>
          <FormColControl>
            <SegmentDropdown
              required
              value={fields.segmentId}
              onChange={setStateFromEvent(setField('segmentId'))}
              error={!!errors.segmentId}
              helperText={errors.segmentId}
            />
          </FormColControl>
          <FormColControl />
        </FormRow>
        {isLoadingVehicles && <Loader />}
        {!isLoadingVehicles && !fields.segmentId && (
          <Alert severity="warning">{t('vehicles:ui.SelectSegmentBeforeVehicles')}</Alert>
        )}
        {!isLoadingVehicles && fields.segmentId && (
          <>
            <Alert severity="info" style={{ marginBottom: '1rem' }}>
              {t('vehicles:ui.SelectFleetVehicles')}
            </Alert>
            <FilterForm>
              <TextField
                label={t('common:generic.FilterBy', { filter: 'description' })}
                style={{ width: '100%' }}
                value={filterValue}
                onChange={setStateFromEvent(setFilterValue)}
              />
            </FilterForm>
            <ChangeListTable
              name="vehiclesFleetForm"
              data={vehicles.filter(
                (v) =>
                  v.segmentId === fields.segmentId &&
                  (!filterValue || (v.description && v.description.toLowerCase().includes(filterValue.toLowerCase()))),
              )}
              selectable
              selected={selected}
              columns={vehicleColumns(t)}
              sortField="serialNumber"
              sortDirection="asc"
              listDisplay={['description', 'engineType.code', 'platform.description', 'program.description']}
              onSelect={setSelected}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('common:buttons.Cancel')}
        </Button>
        <Button onClick={submit} color="primary">
          {t('common:buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

VehiclesFleetForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  vehiclesFleet: VehiclesFleetType,
  vehicles: PropTypes.arrayOf(VehicleType),
}

export default VehiclesFleetForm
