import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { humanizeSize } from '../Utils/Files'
import { FieldType } from '../Types/Field'

const DropZone = styled.div`
  align-items: center;
  background: ${(props) => (props.isDragActive ? '#f3f3f3' : '#fff')};
  border: 2px dashed ${(props) => (props.error ? '#f44336' : '#e3e3e3')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
`

const Help = styled.div`
  color: ${(props) => (props.error ? '#f44336' : 'rgba(0, 0, 0, 0.54)')};
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`

const FileField = ({ onDrop, multiple, files, accept, error, helperText }) => {
  const { t } = useTranslation()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple, accept })
  return (
    <>
      <DropZone {...getRootProps()} isDragActive={isDragActive} error={error}>
        <input {...getInputProps()} />
        <div>
          {isDragActive ? t(`common:ui.DropFile${multiple ? 's' : ''}`) : t(`common:ui.DnDFile${multiple ? 's' : ''}`)}
        </div>
      </DropZone>
      {helperText && <Help error={error}>{helperText}</Help>}
      {files.length !== 0 && (
        <div style={{ marginTop: '2rem' }}>
          <strong>{t(`common:ui.SelectedFile${multiple ? 's' : ''}`)}:</strong>
          <ul>
            {files.map((f, idx) => (
              <li key={'file-' + idx}>
                {f.name} ({humanizeSize(f.size)})
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

FileField.defaultProps = {
  multiple: false,
  accept: '*',
}

FileField.propTypes = {
  ...FieldType,
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  files: PropTypes.array,
  accept: PropTypes.string,
}

export default FileField
