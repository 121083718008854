import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { request as gpdPhasesRequest, selectGpdPhasesData } from '../../Core/Redux/GpdPhases'
import { FieldType } from '../Types/Field'
import Dropdown from './Dropdown'

const GpdPhaseDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const gpdPhases = useSelector(selectGpdPhasesData)

  return (
    <Dropdown
      label={label || t('common:fields.gpdPhase')}
      required={required}
      value={value}
      onChange={onChange}
      options={gpdPhases.length ? gpdPhases.map((s) => ({ value: s.id, label: s.description })) : undefined}
      requestAction={gpdPhasesRequest}
      error={error}
      helperText={helperText}
    />
  )
}

GpdPhaseDropdown.defaultProps = {
  required: false,
}

GpdPhaseDropdown.propTypes = FieldType

export default GpdPhaseDropdown
