import PropTypes from 'prop-types'
import { DeviceType } from '../../Devices/Types/Device'

export const VehicleType = PropTypes.shape({
  id: PropTypes.number,
  createdBy: PropTypes.string,
  createdOn: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string,
  description: PropTypes.string,
  vehicleStatusId: PropTypes.number,
  modelId: PropTypes.number,
  model: PropTypes.shape({
    description: PropTypes.string,
    createdBy: PropTypes.string,
    createdOn: PropTypes.string,
  }),
  device: DeviceType,
  plateNumber: PropTypes.string,
  serialNumber: PropTypes.string,
  segmentId: PropTypes.number,
  segment: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  platformId: PropTypes.number,
  platform: PropTypes.shape({
    externalCode: PropTypes.string,
    description: PropTypes.string,
  }),
  productLineId: PropTypes.number,
  programId: PropTypes.number,
  program: PropTypes.shape({
    description: PropTypes.string,
    createdBy: PropTypes.string,
    createdOn: PropTypes.string,
  }),
  notes: PropTypes.string,
  ownerId: PropTypes.number,
  engineTypeId: PropTypes.number,
  engineType: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  emissionLevelId: PropTypes.number,
  ctsVehicleId: PropTypes.string,
  gpdPhaseId: PropTypes.number,
  pvResponsible: PropTypes.string,
})
