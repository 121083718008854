import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color, style }) => {
  return (<svg width="100%" height="100%" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2, ...style }}><g transform="matrix(1,0,0,1,-331,-295)"><g fill={color} transform="matrix(0.483203,0,0,0.956944,0,0)"><g id="export_config_package" transform="matrix(0.357763,0,0,0.18065,-283.961,223.587)"><path d="M2812.73,534.521C2812.96,532.67 2813.13,530.819 2813.13,528.91C2813.13,527.001 2812.96,525.092 2812.73,523.125L2824.93,513.697C2826.03,512.829 2826.32,511.267 2825.63,509.994L2814.06,489.98C2813.37,488.707 2811.8,488.186 2810.53,488.707L2796.13,494.492C2793.12,492.236 2789.99,490.269 2786.35,488.823L2784.21,473.493C2783.98,472.105 2782.76,471.064 2781.32,471.064L2758.18,471.064C2756.73,471.064 2755.52,472.105 2755.29,473.493L2753.15,488.823C2749.5,490.269 2746.38,492.236 2743.37,494.492L2728.97,488.707C2727.7,488.186 2726.13,488.707 2725.44,489.98L2713.87,509.994C2713.12,511.267 2713.47,512.829 2714.56,513.697L2726.77,523.125C2726.54,525.092 2726.36,527.001 2726.36,528.91C2726.36,530.819 2726.54,532.67 2726.77,534.521L2714.56,544.124C2713.47,544.991 2713.12,546.553 2713.87,547.826L2725.44,567.841C2726.13,569.113 2727.7,569.576 2728.97,569.113L2743.37,563.271C2746.38,565.585 2749.5,567.551 2753.15,568.998L2755.29,584.327C2755.52,585.715 2756.73,586.756 2758.18,586.756L2781.32,586.756C2782.76,586.756 2783.98,585.715 2784.21,584.327L2786.35,568.998C2789.99,567.494 2793.12,565.585 2796.13,563.271L2810.53,569.113C2811.8,569.576 2813.37,569.113 2814.06,567.841L2825.63,547.826C2826.32,546.553 2826.03,544.991 2824.93,544.124L2812.73,534.521ZM2769.74,502.918L2745.74,530.337L2759.45,530.337L2759.45,550.902L2780.02,550.902L2780.02,530.337L2793.73,530.337L2769.74,502.918Z"/></g></g></g></svg>) // eslint-disable-line
}

Icon.defaultProps = {
  color: '#fff',
  style: {},
}

Icon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
