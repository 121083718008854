import React from 'react'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../Auth/Redux/Auth'
import { useTranslation } from 'react-i18next'
import config from '../Config'
import history from '../history'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import VehicleIcon from '../../Common/Icons/Vehicle'
import DeviceIcon from '../../Common/Icons/Device'
import ResourceIcon from '../../Common/Icons/Resource'
import UsersIcon from '../../Common/Icons/Users'
import ReportIcon from '../../Common/Icons/Report'
import SettingsIcon from '../../Common/Icons/Settings'
import ConfiguratorIcon from '../../Common/Icons/Configurator'
import UpdateBundlesIcon from '../../Common/Icons/Update'
import BaseLayout from '../Layouts/BaseLayout'
import { VehiclesPermissions, DevicesPermissions, UsersPermissions, ResourcesPermissions } from '../../Auth/Permissions'
import styled from 'styled-components'

const CardContentGradient = styled(CardContent)`
  background: linear-gradient(to right, ${(props) => props.from}, ${(props) => props.to});
`
const CardContentText = styled(CardContent)`
  background: #f3f3f3;
`

const SectionCard = styled(Card)`
  cursor: pointer;
`

const Title = styled.h2`
  font-weight: normal;
  margin: 0 0 0.8rem;
  text-transform: uppercase;
`
const Text = styled.p`
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0;
`

const IconStyle = {
  display: 'block',
  height: '100px',
  margin: '2rem auto',
}

const HomeView = () => {
  const { t } = useTranslation()
  const user = useSelector(selectAuth)

  const goTo = (path) => () => history.push(path)

  return (
    <BaseLayout>
      <Container style={{ marginTop: '4rem' }}>
        <Grid container spacing={4}>
          {VehiclesPermissions.view(user) && (
            <Grid item xs={6} sm={3}>
              <SectionCard onClick={goTo(config.urls.vehicles)}>
                <CardContentGradient from="#b34f52" to="#d7a8aa">
                  <VehicleIcon style={IconStyle} />
                </CardContentGradient>
                <CardContentText>
                  <Title>{t('core:views.home.VehiclesTitle')}</Title>
                  <Text>{t('core:views.home.VehiclesDesc')}</Text>
                </CardContentText>
              </SectionCard>
            </Grid>
          )}
          {DevicesPermissions.view(user) && (
            <Grid item xs={6} sm={3}>
              <SectionCard onClick={goTo(config.urls.devices)}>
                <CardContentGradient from="#f3be22" to="#f4d481">
                  <DeviceIcon style={IconStyle} />
                </CardContentGradient>
                <CardContentText>
                  <Title>{t('core:views.home.DevicesTitle')}</Title>
                  <Text>{t('core:views.home.DevicesDesc')}</Text>
                </CardContentText>
              </SectionCard>
            </Grid>
          )}
          <Grid item xs={6} sm={3}>
            <SectionCard>
              <CardContentGradient from="#637a85" to="#b2bcc1">
                <ConfiguratorIcon style={IconStyle} />
              </CardContentGradient>
              <CardContentText>
                <Title>{t('core:views.home.ConfiguratorTitle')}</Title>
                <Text>{t('core:views.home.ConfiguratorDesc')}</Text>
              </CardContentText>
            </SectionCard>
          </Grid>
          {ResourcesPermissions.view(user) && (
            <Grid item xs={6} sm={3}>
              <SectionCard onClick={goTo(config.urls.resources)}>
                <CardContentGradient from="#87b897" to="#c2d9ca">
                  <ResourceIcon style={IconStyle} />
                </CardContentGradient>
                <CardContentText>
                  <Title>{t('core:views.home.ResourcesTitle')}</Title>
                  <Text>{t('core:views.home.ResourcesDesc')}</Text>
                </CardContentText>
              </SectionCard>
            </Grid>
          )}
          {UsersPermissions.view(user) && (
            <Grid item xs={6} sm={3}>
              <SectionCard onClick={goTo(config.urls.users)}>
                <CardContentGradient from="#9866cc" to="#c1a4de">
                  <UsersIcon style={IconStyle} />
                </CardContentGradient>
                <CardContentText>
                  <Title>{t('core:views.home.UsersTitle')}</Title>
                  <Text>{t('core:views.home.UsersDesc')}</Text>
                </CardContentText>
              </SectionCard>
            </Grid>
          )}
          <Grid item xs={6} sm={3}>
            <SectionCard>
              <CardContentGradient from="#4bb5d5" to="#9bd3e5">
                <ReportIcon style={IconStyle} />
              </CardContentGradient>
              <CardContentText>
                <Title>{t('core:views.home.ReportsTitle')}</Title>
                <Text>{t('core:views.home.ReportsDesc')}</Text>
              </CardContentText>
            </SectionCard>
          </Grid>
          <Grid item xs={6} sm={3}>
            <SectionCard>
              <CardContentGradient from="#5062de" to="#9da6e8">
                <SettingsIcon style={IconStyle} />
              </CardContentGradient>
              <CardContentText>
                <Title>{t('core:views.home.SettingsTitle')}</Title>
                <Text>{t('core:views.home.SettingsDesc')}</Text>
              </CardContentText>
            </SectionCard>
          </Grid>
          <Grid item xs={6} sm={3}>
            <SectionCard>
              <CardContentGradient from="#d6782a" to="#e4ae86">
                <UpdateBundlesIcon style={IconStyle} />
              </CardContentGradient>
              <CardContentText>
                <Title>{t('core:views.home.UpdateBundlesTitle')}</Title>
                <Text>{t('core:views.home.UpdateBundlesDesc')}</Text>
              </CardContentText>
            </SectionCard>
          </Grid>
        </Grid>
      </Container>
    </BaseLayout>
  )
}

export default HomeView
