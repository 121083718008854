import AuthReducer from './Auth'
import UsersReducer, { fetchUsersEpic } from './Users'
import RolesReducer, { fetchRolesEpic } from './Roles'

export const epics = [
  fetchUsersEpic,
  fetchRolesEpic,
]

export default {
  auth: AuthReducer,
  users: UsersReducer,
  roles: RolesReducer,
}
