import React from 'react'
import { BreadcrumbsType } from '../../Types/Breadcrumbs'
import PropTypes from 'prop-types'
import Navbar from '../../Components/Navbar'
import Menu from '../../Components/Menu'
import Sidebar from '../../../Common/Components/Sidebar'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Container = styled.div`
  display: flex;
  flex-grow: 1;
`

const Content = styled.main`
  flex-grow: 1;
  overflow: auto;
`

const BaseLayout = ({ children, showMenu, breadcrumbs, sidebar }) => {
  return (
    <Wrapper>
      <Navbar breadcrumbs={breadcrumbs} />
      <Container>
        {showMenu && <Menu />}
        {sidebar && <Sidebar>{sidebar}</Sidebar>}
        <Content>
          {children}
        </Content>
      </Container>
    </Wrapper>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  sidebar: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showMenu: PropTypes.bool,
  breadcrumbs: BreadcrumbsType,
}

export default BaseLayout
