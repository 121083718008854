import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from './TextField'
import { FieldType } from '../Types/Field'

const TagField = ({ value, onChange, label, error, helperText }) => {
  const { t } = useTranslation()
  return (
    <Autocomplete
      multiple
      defaultValue={value}
      options={[]}
      onChange={(_, tags) => {
        onChange(tags)
      }}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          placeholder={t('common:ui.TagFieldPlaceholder')}
        />
      )}
    />
  )
}

TagField.propTypes = {
  ...FieldType,
}

export default TagField
